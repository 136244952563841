import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getEducationBreakdown } from "../../service/ProviderStats";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";

const EducationBreakdown = ({ panelProvider }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const getData = useCallback(async () => {
    setError("");
    setLoading(true);

    const authToken = await getAccessTokenSilently();
    const { data, error } = await getEducationBreakdown(
      panelProvider,
      authToken
    );
    const educationData = data?.data?.sort((a, b) => +a.value - +b.value);
    setLoading(false);
    return { data: educationData || [], error: error };
  }, [panelProvider, getAccessTokenSilently]);

  useEffect(() => {
    let mounted = true;
    if (panelProvider) {
      getData().then(({ data, error }) => {
        if (mounted) {
          setData(data);
          if (error) setError("Education data could not be loaded");
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [panelProvider, getData]);

  const handleRetry = async () => {
    const { data, error } = await getData(true);
    setData(data);
    if (error) setError("Education data could not be loaded");
  };

  const getChartOptions = (genderBreakdownData) => {
    const max = genderBreakdownData.reduce(
      (acum, curr) => 1 * acum + 1 * curr.value,
      0
    );
    return {
      tooltip: {
        formatter: (params) =>
          `${params?.[0].name} </br> ${params?.[0].value} Users </br> ${(
            (params?.[0].value / max) *
            100
          )?.toFixed(2)}%`,
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: {
        type: "category",
        data: genderBreakdownData.map((val) => val.key),
        axisLabel: {
          overflow: "truncate",
          width: "100",
          hideOverlap: true
        },
      },
      xAxis: {
        type: "value",
        axisLabel: {
          hideOverlap: true,
        },
      },
      grid: {
        left: 110,
        right: 0,
      },
      series: [
        {
          data: genderBreakdownData.map((value) => {
            return {
              value: 1 * value.value,
              name: value.key,
            };
          }),
          name: "Education Breakdown",
          type: "bar",
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#8D4BF3",
              },
              {
                offset: 1,
                color: "#5C5BF7",
              },
            ]),
            borderRadius: [0, 10, 10, 0],
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: false,
            },
          },
        },
      ],
    };
  };

  const dataLoaded = () => {
    return data && data.length > 0;
  };

  return (
    <div className="graphChart">
      <div className="graphHeaderContainer">
        <p className="chartTitle">Education Breakdown</p>
        <button
          className="refreshButton"
          title="Reload Gross Revenue chart"
          onClick={handleRetry}
        >
          <RefreshIcon />
        </button>
      </div>
      {error && (
        <p className="errorMessage error">Error loading data: {error}</p>
      )}
      {loading && (
        <div className="graphMessageContainer">
          <div className="loaderSpinner"/>
        </div>
      )}
      {!loading && !error && !dataLoaded() && (
        <div className="graphMessageContainer">
          <p>No Data</p>
        </div>
      )}
      {!error && dataLoaded() && (
        <ReactEcharts
          option={getChartOptions(data)}
          style={{ height: "100%", width: "100%" }}
          className="chart"
        />
      )}
    </div>
  );
};

export default EducationBreakdown;
