import React, { useState, useEffect } from "react";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";

const LoiBracketRow = ({ bracket, changeBracket, handleDelete }) => {
  const [edit, setEdit] = useState(false);
  const [reward, setReward] = useState(bracket.reward);
  const [loiLimit, setLoiLimit] = useState(bracket.loi_limit);

  useEffect(() => {
    setLoiLimit(bracket.loi_limit);
    setReward(bracket.reward);
  }, [edit, bracket.reward, bracket.loi_limit]);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleSave = async() => {
    const success = await changeBracket(bracket.id, reward, loiLimit);
    if(success) setEdit(false);
  };

  if (edit) {
    return (
      <tr>
        <td data-label="Loi Limit">
          <input
            className="loiInput"
            type="number"
            title="loi limit"
            value={loiLimit}
            onChange={(e) => {
              setLoiLimit(e.target.value);
            }}
          />
        </td>
        <td data-label="Reward">
          <input
            type="number"
            value={reward}
            onChange={(e) => {
              setReward(e.target.value);
            }}
          />
        </td>
        <td>
          <div
          className="tableButtonContainer"
          >
            <button
              key="save"
              className="button saveButton"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              key="cancel"
              className="button resetButton"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td data-label="Loi Limit">{bracket.loi_limit} Minutes</td>
      <td data-label="Reward">{bracket.reward}</td>
      <td className="tableButtonContainer">
        <button className="button editButton" onClick={handleEdit} key="edit">
          Edit
        </button>
        <button
          className="button deleteButton"
          onClick={() => handleDelete(bracket.id)}
        >
          <DeleteIcon className="icon" />
        </button>
      </td>
    </tr>
  );
};

export default LoiBracketRow;
