import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getPanelProvidersUsers,
  getUserInviteLink,
  createNewUser as cnu,
  updateUser as uu,
  deleteUser as du,
} from "../service/Users";
import "./Users.scss";
import UserModal from "../components/UserModal";
import "bulma/css/bulma.css";
import UserContext from "../shared/user-context";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as DeleteIcon } from "../assets/delete-icon.svg";
import DeleteModal from "../components/Shared/DeleteModal";

const Users = ({ match }) => {
  const panelProvider = match.params.panel_provider;
  const { getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [inviteLink, setInviteLink] = useState("");
  const [showInviteLinkModal, setShowInviteLinkModal] = useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserAdminFlag, setNewUserAdminFlag] = useState(false);
  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserAdminFlag, setEditUserAdminFlag] = useState(false);
  const [qmeeAdmin, setQmeeAdmin] = useState(false);
  const [customPanelProvider, setCustomPanelProvider] = useState("");
  const [deleteModalVis, setDeleteModalVis] = useState(false);

  const currentUser = useContext(UserContext);

  useEffect(() => {
    if (panelProvider) {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const users = await getPanelProvidersUsers(panelProvider, accessToken)
          users.sort(( a, b ) => a.created_at - b.created_at)
          setUsers(users);
        } catch (error) {
          console.log(error.message);
        }
      })();
    }
  }, [getAccessTokenSilently, panelProvider]);

  useEffect(() => {
    if (panelProvider) setCustomPanelProvider(panelProvider);
  }, [panelProvider]);

  const updateInviteLink = async (userID) => {
    try {
      const accessToken = await getAccessTokenSilently();
      setInviteLink(await getUserInviteLink(accessToken, userID));
    } catch (error) {
      console.error(error.message);
    }
  };

  const getInviteLink = (event, userID) => {
    event.preventDefault();
    updateInviteLink(userID);
    setShowInviteLinkModal(true);
  };

  const notify = (message) => {
    toast.error(message);
  };

  const createNewUser = async (payload) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const newUser = await cnu(accessToken, payload);
      if (typeof newUser !== "undefined") {
        setUsers([...users, newUser]);
      }
    } catch (error) {
      console.warn(error.message);
      notify(error.message);
    }
  };

  const updateUser = async (payload) => {
    try {
      const accessToken = await getAccessTokenSilently();
      await uu(accessToken, selectedUser.id, payload);

      const userIndex = users.findIndex((u) => u.id === selectedUser.id);
      if (userIndex !== -1) {
        users[userIndex].provider_admin = newUserAdminFlag;
        users[userIndex].qmee_admin = qmeeAdmin;
        setUsers([...users]);
      }
      setShowEditUserModal(false);
    } catch (error) {
      notify(error.message);
      console.log(error.message);
    }
  };

  const deleteUser = async (userID) => {
    try {
      const accessToken = await getAccessTokenSilently();
      await du(accessToken, userID);
      let usersCopy = [...users];
      usersCopy.splice(
        users.findIndex((u) => u.id === userID),
        1
      );
      setUsers(usersCopy);
    } catch (error) {
      console.warn(error.message);
      notify(error.message);
    }
  };

  const saveNewUser = (event) => {
    event.preventDefault();
    createNewUser({
      email: newUserEmail,
      provider_name: customPanelProvider,
      provider_admin: newUserAdminFlag,
      qmee_admin: qmeeAdmin,
    });
    setShowCreateUserModal(false);
  };

  const editUser = (event) => {
    event.preventDefault();
    var payload = {
      email: editUserEmail,
      provider_admin: editUserAdminFlag,
      qmee_admin: currentUser.qmee_admin ? qmeeAdmin : undefined,
    };
    updateUser(payload);
  };

  const onEditUser = (event, index) => {
    event.preventDefault();

    setSelectedUser(users[index]);
    setEditUserEmail(users[index].email);
    setEditUserAdminFlag(users[index].provider_admin);
    setQmeeAdmin(users[index].qmee_admin);
    setShowEditUserModal(true);
  };

  const onCreateUser = (event) => {
    setShowCreateUserModal(true);
    setEditUserEmail("");
    setCustomPanelProvider(currentUser?.panel_provider);
    setEditUserAdminFlag(false);
    setQmeeAdmin(false);
  };

  const handleDelete = (event, index) => {
    event.preventDefault();
    setSelectedUser(users[index]);
    setDeleteModalVis(true);
  };

  const confirmDelete = () => {
    deleteUser(selectedUser.id);
    setDeleteModalVis(false);
  };

  const closeDeleteModal = () => {
    setSelectedUser({});
    setDeleteModalVis(false);
  };

  return (
    <section className="section" style={{ paddingTop: "0", marginTop: "0" }}>
      <ToastContainer />
      {deleteModalVis && (
        <DeleteModal
          onClose={closeDeleteModal}
          onDelete={confirmDelete}
          header="Confirm Delete User?"
          message={`Are you sure you want to delete user: ${selectedUser.email}?`}
        />
      )}
      <div className="container usersPage">
        <div className="header">
          <h1
            style={{ marginRight: "auto", marginBottom: "4.8rem" }}
            className="title"
          >
            Users
          </h1>
          <button
            style={{ margin: "auto 0 auto auto" }}
            className="button is-success createButton"
            onClick={onCreateUser}
          >
            <span className="plus">+</span>Create
          </button>
        </div>
        <div className="table-container users-table">
          <table className="table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Created At</th>
                <th>Email</th>
                <th>Last logged in</th>
                <th style={{ textAlign: "center" }}>Admin User</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, index) => {
                  return (
                    <tr
                      id={`user${user.id}-row`}
                      key={index}
                      style={{ cursor: "pointer", verticalAlign: "middle" }}
                    >
                      <td style={{ verticalAlign: "middle" }}>
                        {user?.created_at &&
                          new Date(user.created_at * 1000)
                            .toLocaleString()
                            .substring(0, 10)}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>{user.email}</td>
                      <td style={{ verticalAlign: "middle" }}>
                        {user?.last_logged_in &&
                          new Date(user.last_logged_in * 1000)
                            .toLocaleString()
                            .substring(0, 10)}
                      </td>
                      <td
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        <input
                          id="provider-admin"
                          type="checkbox"
                          value="Provider Admin"
                          checked={user.provider_admin}
                          readOnly={true}
                        ></input>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <button
                          id={`user${user.id}-invite`}
                          className="button userButton"
                          disabled={user.last_logged_in}
                          onClick={(e) => getInviteLink(e, user.id)}
                        >
                          Send Invite
                        </button>
                      </td>
                      <td className="settingsButtons">
                        <button
                          className="button userButton"
                          onClick={(e) => onEditUser(e, index)}
                        >
                          Edit
                        </button>
                        <button
                          className="button deleteButton"
                          onClick={(e) => handleDelete(e, index)}
                        >
                          <DeleteIcon className="icon" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {(!users || !users.length) && (
              <div
                style={{ display: "block", width: "100%", textAlign: "center" }}
              >
                <p>No users to show</p>
              </div>
            )}
        </div>
      </div>
      <div
        id="invite-link-modal"
        className={showInviteLinkModal ? "modal is-active" : "modal"}
      >
        <div
          className="modal-background"
          onClick={() => setShowInviteLinkModal(false)}
        ></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Invite sent</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setShowInviteLinkModal(false)}
            ></button>
          </header>
          <section className="modal-card-body">{inviteLink}</section>
          <footer className="modal-card-foot">
            <button
              className="button"
              onClick={() => setShowInviteLinkModal(false)}
            >
              Cancel
            </button>
          </footer>
        </div>
      </div>
      <UserModal
        id="edit-user-modal"
        showModal={showEditUserModal}
        setShowModal={setShowEditUserModal}
        setUserEmail={setEditUserEmail}
        userEmail={editUserEmail}
        setUserAdminFlag={setEditUserAdminFlag}
        userAdminFlag={editUserAdminFlag}
        onSuccessClick={editUser}
        modalCreateMode={false}
        successButtonLabel={"Update"}
        qmeeAdmin={qmeeAdmin}
        setQmeeAdmin={setQmeeAdmin}
        isQmeeAdmin={currentUser?.qmee_admin}
      />
      <UserModal
        id="create-user-modal"
        showModal={showCreateUserModal}
        setShowModal={setShowCreateUserModal}
        setUserEmail={setNewUserEmail}
        userEmail={newUserEmail}
        setUserAdminFlag={setNewUserAdminFlag}
        userAdminFlag={newUserAdminFlag}
        onSuccessClick={(e) => saveNewUser(e)}
        modalCreateMode={true}
        successButtonLabel={"Create"}
        qmeeAdmin={qmeeAdmin}
        setQmeeAdmin={setQmeeAdmin}
        setPanelProviderValue={setCustomPanelProvider}
        panelProviderValue={customPanelProvider}
        isQmeeAdmin={currentUser?.qmee_admin}
      />
    </section>
  );
};

export default Users;
