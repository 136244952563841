import axios from "axios";
import { formatMomentToTimeString } from "../utils/formatToUTCDate";
import { BACKEND_URL } from "./useProviderUserRewardConfigs";
import moment from "moment-timezone";


const getCurrentTime = (timeZone, interval) =>{
  const time = moment();
  if(interval === 'w' || interval === 'm') timeZone = 'UTC';
  if(timeZone) return time.tz(timeZone);

  return time;
};

const createStatsEndpoint = (statsEndpoint = '', timeZone, interval) => {
  if(interval === 'w' || interval === 'm') timeZone = 'UTC';
  if(timeZone && timeZone !== "UTC") statsEndpoint += `tz=${timeZone}&`

  return statsEndpoint;
};

export const getStartsPerUser = async (panel_provider, authToken) => {
  return await queryPanelProviderStats(panel_provider, 'starts-per-user', authToken);
};

export const getGenderBreakdown = async (panel_provider, authToken) => {
  return await queryDemographicPanelProviderStats(panel_provider, 'gender-breakdown', authToken);
};

export const getAgeBreakdown = async (panel_provider, authToken) => {
  return await queryDemographicPanelProviderStats(panel_provider, 'age-breakdown', authToken);
};

export const getUserBreakdownByState = async (panel_provider, authToken) => {
  return await queryDemographicPanelProviderStats(panel_provider, 'us-state-breakdown', authToken);
};

export const getEthnicityBreakdown = async (panel_provider, authToken) => {
  return await queryDemographicPanelProviderStats(panel_provider, 'ethnicity-breakdown', authToken);
};

export const getEducationBreakdown = async (panel_provider, authToken) => {
  return await queryDemographicPanelProviderStats(panel_provider, 'education-breakdown', authToken);
};

export const getEmploymentStatusBreakdown = async (panel_provider, authToken) => {
  return await queryDemographicPanelProviderStats(panel_provider, 'employment-status-breakdown', authToken);
};

export const getIncomeBreakdown = async (panel_provider, authToken) => {
  return await queryDemographicPanelProviderStats(panel_provider, 'income-breakdown', authToken);
};

export const getMaritalStatusBreakdown = async (panel_provider, authToken) => {
  return await queryDemographicPanelProviderStats(panel_provider, 'marital-breakdown', authToken);
};

export const getEmploymentContractBreakdown = async (panel_provider, authToken) => {
  return await queryDemographicPanelProviderStats(panel_provider, 'employment-contract-breakdown', authToken);
};

export const getMonthlyReconcileRate = async (panel_provider, authToken) => {
  return await queryPanelProviderStats(panel_provider, 'monthly-reconcile-rate', authToken)
}

export const getEngagementRate = async (
  panel_provider,
  authToken,
  interval,
  timeZone
) => {
  const startTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint(`engagement-rate-by-date?`, timeZone, interval);

  switch (interval) {
    case "d":
    case "h":
      startTime.hours(0);
      statsEndpoint += `start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    case "m":
      startTime.date(1);
      startTime.hours(0);
      statsEndpoint += `start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "w":
      startTime.startOf('week');
      statsEndpoint += `start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    default:
      return { error: "invalid interval" };
  }

  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};

export const getCurrentActiveUsers = async (panel_provider, authToken, timeZone) => {
  const startTime = getCurrentTime(timeZone);
  let statsEndpoint = createStatsEndpoint(`unique-users-by-date?`, timeZone);

  startTime.date(startTime.date() - 13);
  startTime.hours(0);
  statsEndpoint += `start=${formatMomentToTimeString(startTime, timeZone)}`;
  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};

export const getConversionRate = async (
  interval,
  panel_provider,
  authToken,
  timeZone
) => {
  const startTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint('conversion-rate-by-date?', timeZone, interval);

  switch (interval) {
    case "d":
      startTime.hours(0);
      statsEndpoint += `interval=d&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    case "m":
      startTime.date(startTime.date() - 29);
      startTime.startOf('day');
      statsEndpoint += `start=${formatMomentToTimeString(startTime, 'UTC', false)}`;
      break;
    case "w":
      startTime.date(startTime.date() - 6);
      startTime.startOf('day');
      statsEndpoint += `start=${formatMomentToTimeString(startTime, 'UTC', false)}`;
      break;
    case "h":
      startTime.hours(startTime.hours() - 1);
      statsEndpoint += `interval=h&start=${formatMomentToTimeString(startTime, timeZone, true)}`;
      break;
    default:
      return { error: "invalid interval" };
  }

  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};

export const getConversionRateChart = async (
  interval,
  panel_provider,
  authToken,
  timeZone
) => {
  const startTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint('conversion-rate-by-date?', timeZone, interval);

  switch (interval) {
    case "d":
      startTime.date(startTime.date() - 13);
      startTime.startOf('day')
      statsEndpoint += `interval=d&start=${formatMomentToTimeString(
        startTime,
        timeZone
      )}`;
      break;
    case "m":
      startTime.month(startTime.month() - 11);
      startTime.startOf('month');
      statsEndpoint += `interval=m&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "w":
      startTime.date(startTime.date() - 13 * 7);
      startTime.startOf("week");
      statsEndpoint += `interval=w&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "h":
      startTime.hours(startTime.hours() - 23);
      statsEndpoint += `interval=h&start=${formatMomentToTimeString(
        startTime,
        timeZone
      )}`;
      break;
    default:
      return { error: "invalid interval" };
  }

  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};

export const getCompletes = async (interval, panel_provider, authToken, timeZone) => {
  const startTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint('completes-by-date?', timeZone, interval);

  switch (interval) {
    case "d":
      startTime.date(startTime.date() - 13);
      startTime.hours(0)
      statsEndpoint += `interval=d&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    case "m":
      startTime.month(startTime.month() - 11);
      startTime.date(1);
      startTime.hours(0);
      statsEndpoint += `interval=m&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "w":
      startTime.startOf("week");
      startTime.date(startTime.date() - 13 * 7);
      statsEndpoint += `interval=w&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "h":
      startTime.hours(startTime.hours() - 23);
      statsEndpoint += `interval=h&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    default:
      return { error: "invalid interval" };
  }

  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};

export const getEarningsPerClick = async (
  interval,
  panel_provider,
  authToken,
  timeZone
) => {
  const startTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint('earnings-per-click-by-dates?', timeZone, interval);

  switch (interval) {
    case "d":
      startTime.hours(startTime.hours() - 24);
      statsEndpoint += `start=${formatMomentToTimeString(startTime, timeZone, true)}`;
      break;
    case "m":
      startTime.date(startTime.date() - 30);
      statsEndpoint += `start=${formatMomentToTimeString(startTime, 'UTC', true)}`;
      break;
    case "w":
      startTime.date(startTime.date() - 7);
      statsEndpoint += `start=${formatMomentToTimeString(startTime, 'UTC', true)}`;
      break;
    case "h":
      startTime.hours(startTime.hours() - 1);
      statsEndpoint += `interval=h&start=${formatMomentToTimeString(startTime, timeZone, true)}`;
      break;
    default:
      return { error: "invalid interval" };
  }

  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};

export const getProviderRevenue = async (
  interval,
  panel_provider,
  authToken,
  timeZone
) => {
  const startTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint('revenue-by-date?', timeZone, interval);
  
  switch (interval) {
    case "d":
      startTime.hours(0);
      startTime.date(startTime.date() - 13);
      statsEndpoint += `interval=d&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    case "m":
      startTime.month(startTime.month() - 11);
      startTime.date(1)
      startTime.hours(0)
      statsEndpoint += `interval=m&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "w":
      startTime.date(startTime.date()- 13 * 7);
      startTime.startOf('week');
      statsEndpoint += `interval=w&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "h":
      startTime.hours(startTime.hours() - 23)
      statsEndpoint += `interval=h&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    default:
      return { error: "invalid interval" };
  }

  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};

export const getActiveUsers = async (
  interval,
  panel_provider,
  authToken,
  timeZone
) => {
  const startTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint('active-users-by-date?', timeZone, interval);

  switch (interval) {
    case "d":
      startTime.date(startTime.date() - 13);
      startTime.hours(0);
      statsEndpoint += `interval=d&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    case "m":
      startTime.month(startTime.month() - 11);
      startTime.date(1);
      startTime.hours(0);
      statsEndpoint += `interval=m&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "w":
      startTime.startOf('week');
      startTime.date(startTime.date() - 13 * 7);
      statsEndpoint += `interval=w&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "h":
      startTime.hours(startTime.hours() - 23)
      statsEndpoint += `interval=h&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    default:
      return { error: "invalid interval" };
  }

  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};

export const getAverageCPICompletion = async (
  interval,
  panel_provider,
  authToken,
  timeZone
) => {
  const startTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint('average-completes-cpi?', timeZone, interval);

  switch (interval) {
    case "d":
      startTime.date(startTime.date() - 13);
      startTime.hours(0);
      statsEndpoint += `interval=d&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    case "m":
      startTime.month(startTime.month() - 11);
      startTime.date(1);
      startTime.hours(0);
      statsEndpoint += `interval=m&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "w":
      startTime.startOf('week');
      startTime.date(startTime.date() - 13 * 7);
      statsEndpoint += `interval=w&start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "h":
      startTime.hours(startTime.hours() - 23)
      statsEndpoint += `interval=h&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    default:
      return { error: "invalid interval" };
  }

  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
}

export const getCurrentRevenue = async (
  interval,
  panel_provider,
  authToken,
  timeZone
) => {

  const startTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint('revenue-by-date?', timeZone, interval);

  switch (interval) {
    case "d":
      startTime.hours(0);
      statsEndpoint += `start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    case "m":
      startTime.date(1);
      startTime.hours(0);
      statsEndpoint += `start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "w":
      startTime.startOf('week');
      statsEndpoint += `start=${formatMomentToTimeString(startTime, 'UTC')}`;
      break;
    case "h":
      statsEndpoint += `interval=h&start=${formatMomentToTimeString(startTime, timeZone)}`;
      break;
    default:
      return { error: "invalid interval" };
  }
  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};

export const getLastRevenue = async (
  interval,
  panel_provider,
  authToken,
  timeZone
) => {

  const currentTime = getCurrentTime(timeZone, interval);
  const previousTime = getCurrentTime(timeZone, interval);
  let statsEndpoint = createStatsEndpoint('revenue-by-date?', timeZone, interval);

  switch (interval) {
    case "d":
      previousTime.date(previousTime.date() - 1);
      previousTime.hours(0)
      currentTime.hours(0)
      statsEndpoint += `start=${formatMomentToTimeString(
        previousTime,
        timeZone
      )}&end=${formatMomentToTimeString(currentTime, timeZone)}`;
      break;
    case "m":
      previousTime.month(previousTime.month() - 1);
      previousTime.date(1);
      previousTime.hours(0);
      currentTime.date(1);
      currentTime.hours(0);
      statsEndpoint += `start=${formatMomentToTimeString(
        previousTime,
        'UTC'
      )}&end=${formatMomentToTimeString(currentTime, timeZone)}`;
      break;
    case "w":
      previousTime.date(previousTime.date() - 7)
      currentTime.startOf('week');
      previousTime.startOf('week')

      statsEndpoint += `start=${formatMomentToTimeString(
        previousTime,
        'UTC'
      )}&end=${formatMomentToTimeString(currentTime, timeZone)}`;
      break;
    case "h":
      previousTime.hours(previousTime.hours() - 1)
      statsEndpoint += `interval=h&start=${formatMomentToTimeString(
        previousTime,
        timeZone
      )}&end=${formatMomentToTimeString(currentTime, timeZone)}`;
      break;
    default:
      return { error: "invalid interval" };
  }
  return await queryPanelProviderStats(panel_provider, statsEndpoint, authToken);
};


const queryDemographicPanelProviderStats = async (
  panel_provider,
  stats_endpoint,
  authToken
) => {
  let error = "";
  let data = {};
  try {
    const response = await axios.get(
      `${BACKEND_URL}/portal/provider/demographics/${panel_provider}/stats/${stats_endpoint}`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

    data = response.data;
  } catch (err) {
    error = err;
  } finally {
    return { error, data };
  }
};

const queryPanelProviderStats = async (
  panel_provider,
  stats_endpoint,
  authToken
) => {
  let error = "";
  let data = {};
  try {
    const response = await axios.get(
      `${BACKEND_URL}/portal/provider/${panel_provider}/stats/${stats_endpoint}`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

    data = response.data;
  } catch (err) {
    error = err;
  } finally {
    return { error, data };
  }
};
