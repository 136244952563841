import React, { useState, useCallback, useEffect, useRef } from "react";
import EndpointQuestion from "./EndpointQuestion";
import { notify } from "../../utils/notify";
import { getEndpoints, updateEndPoints } from "../../service/EndPoints";
import { compareObjects } from "../../utils/compareObjects";
import { useAuth0 } from "@auth0/auth0-react";
import TestEndpoints from "./TestEndpoints";

const Endpoints = ({ panelProvider }) => {
  const [data, setData] = useState({});
  const [inputData, setInputData] = useState({});
  const [showSave, setShowSave] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const firstRun = useRef(true);

  const getEndpointsData = useCallback(async () => {
    setError("");
    setLoading(true);

    const authToken = await getAccessTokenSilently();
    const { data, error } = await getEndpoints(panelProvider, authToken);

    setData({ ...data });
    setInputData({ ...data });

    setError(error?.message || "");
    setLoading(false);
  }, [getAccessTokenSilently, panelProvider]);

  const updateEndpointsData = async () => {
    if (loading) return;
    setLoading(true);
    setError("");
    const authToken = await getAccessTokenSilently();
    const { error } = await updateEndPoints(
      inputData,
      panelProvider,
      authToken
    );

    if (!error?.message) {
      setData({
        ...inputData,
      });
      notify("successfully updated endpoints");
      setShowSave(false);
    } else {
      setError(error.response?.data || error.message);
      notify(error.response?.data || error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (panelProvider && firstRun.current) {
      firstRun.current = false;
      getEndpointsData(panelProvider);
    }
  }, [panelProvider, getEndpointsData]);

  const handleChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const match = compareObjects(data, inputData);

    if (!match !== showSave) {
      setShowSave(!match);
    }
  }, [inputData, data, showSave]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateEndpointsData();
  };

  const handleCancel = () => {
    setInputData({
      ...data,
    });
  };

  if (error && !Object.keys(data).length) {
    return (
      <div className="container endpointsContainer">
        <h3 className="subtitle is-5">Endpoints</h3>
        <div className="errorContainer">
          <p className="errorMessage">Failed to load: {error}</p>
          <button className="button is-light" onClick={getEndpointsData}>
            Refetch
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container endpointsContainer">
      <h3 className="subtitle is-5">Endpoints</h3>

      <div className="endpointFormContainer">
        <form onSubmit={handleSubmit} className="endpointsForm">
          <EndpointQuestion
            inputId="redirect_url"
            name="redirect"
            labelName="Redirect URL"
            value={inputData.redirect ? inputData.redirect : ""}
            handleChange={handleChange}
            disabled={!Object.keys(data).length}
            required
            message={
              "The url to redirect users to on finishing the survey. Placeholders can be specified that will be substituted on redirect"
            }
          >
            <p>Must include all placeholders</p>
          </EndpointQuestion>

          <EndpointQuestion
            inputId="postback_url"
            name="postback"
            labelName="Postback URL"
            value={inputData.postback ? inputData.postback : ""}
            handleChange={handleChange}
            disabled={!Object.keys(data).length}
            message={<a href="https://developer.qurated.ai/#configuring-your-endpoints" target="_blank">The url to send the postback object to</a>}
            required
          />

          <EndpointQuestion
            inputId="notification_url"
            name="notification"
            labelName="Notifications URL"
            value={inputData.notification ? inputData.notification : ""}
            handleChange={handleChange}
            message={<a href="https://developer.qurated.ai/#configuring-your-endpoints" target="_blank">The url to send user guids to when they have been matched to a good survey</a>}
            disabled={!Object.keys(data).length}
          />

          <EndpointQuestion
            inputId="user_banned_url"
            name="user_banned"
            labelName="User Banned URL"
            value={inputData.user_banned ? inputData.user_banned : ""}
            handleChange={handleChange}
            message={<a href="https://developer.qurated.ai/#configuring-your-endpoints" target="_blank">The url to send user banned events to</a>}
            disabled={!Object.keys(data).length}
          />

          <EndpointQuestion
            inputId="profile_redirect_url"
            name="profile_redirect"
            labelName="Profile Redirect URL"
            value={inputData.profile_redirect ? inputData.profile_redirect : ""}
            message={"The url to send profile redirect events to"}
            disabled={!Object.keys(data).length}
            handleChange={handleChange}
          />

          <EndpointQuestion
            inputId="profiler_postback_url"
            name="profiler_postback"
            labelName="Profiler Postback URL"
            value={inputData.profiler_postback ? inputData.profiler_postback : ""}
            message={"The url to send Profiler events to"}
            disabled={!Object.keys(data).length}
            handleChange={handleChange}
          />

          <EndpointQuestion
            inputId="sensitive_data_changed_postback_url"
            name="sensitive_data_changed_postback"
            labelName="Sensitive Data Changed Postback URL"
            value={inputData.sensitive_data_changed_postback ? inputData.sensitive_data_changed_postback : ""}
            message={"The url to send an event to when a user changes their 'sensitive data' setting"}
            disabled={!Object.keys(data).length}
            handleChange={handleChange}
          />

          {error && <p className="errMessage">{error}</p>}
          {!!Object.keys(data).length && (
            <div className="buttonsContainer">
              <button
                className="button is-info submit"
                type="submit"
                disabled={!showSave || loading}
              >
                Save Changes
              </button>
              <button
                className="button is-light"
                onClick={handleCancel}
                type="button"
                disabled={!showSave || loading}
              >
                Revert
              </button>
            </div>
          )}
        </form>
        <div className="endpointNotes">
          <label className="label">Endpoint Notes</label>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th>Placeholder</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>%status%</td>
                  <td>
                    The status of the survey. Visit the{" "}
                    <a
                      className="externalLink"
                      href="https://developer.qurated.ai/#statuses"
                    >
                      documentation
                    </a>{" "}
                    for a list of statuses.
                  </td>
                </tr>
                <tr>
                  <td>%guid%</td>
                  <td>The user guid.</td>
                </tr>
                <tr>
                  <td>%study_id%</td>
                  <td>
                    The id of the study - this will match the id returned from
                    the available surveys endpoint.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <TestEndpoints data={data} panelProvider={panelProvider} />
        </div>

        {!Object.keys(data).length && !loading && (
          <div className="noDataDiv">
            <p>No Data received</p>
            <button className="button is-light" onClick={getEndpointsData}>
              Refetch
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Endpoints;
