import React from "react";
import "./Overview.scss";
import GenderPieChart from "../components/DemographicStats/GenderPieChart";
import UserBreakdownMap from "../components/DemographicStats/UserBreakdownMap";
import AgeBreakdownChart from "../components/DemographicStats/AgeBreakdownChart";
import EthnicityChart from "../components/DemographicStats/EthnicityChart";
import EmploymentStatusChart from "../components/DemographicStats/EmploymentStatus";
import IncomeBreakdown from "../components/DemographicStats/IncomeBreakdown";
import MaritalStatusChart from "../components/DemographicStats/MaritalStatus";
import EmploymentContractBreakdown from "../components/DemographicStats/EmploymentContract";
import EducationBreakdown from "../components/DemographicStats/EducationBreakdown";

const Demographics = ({ match }) => {
  const panelProvider = match.params.panel_provider;

  return (
    <section className="section">
      <div className="container">
        <div className="title-container">
          <h1 className="title">Demographics</h1>
        </div>

        <div className="graphContainer">
          <UserBreakdownMap panelProvider={panelProvider} />
          <GenderPieChart panelProvider={panelProvider} />
          <AgeBreakdownChart panelProvider={panelProvider} />
          <EthnicityChart panelProvider={panelProvider} />
          <EducationBreakdown panelProvider={panelProvider} />
          <MaritalStatusChart panelProvider={panelProvider} />
          <div className="graphChart">
            <EmploymentStatusChart panelProvider={panelProvider} />
            <EmploymentContractBreakdown panelProvider={panelProvider} />
          </div>
          <IncomeBreakdown panelProvider={panelProvider} />
        </div>
      </div>
    </section>
  );
};

export default Demographics;
