import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css'
import { Redirect } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { getCurrentUser } from "../service/Users";
import Loading from './Loading';

const LoginSuccess = () => {

  const [currentUser, setCurrentUser] = useState(null);

  const { isLoading, getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    const updateCurrentUser = async () => {
      const accessToken = await getAccessTokenSilently();
      getCurrentUser(accessToken)
        .then((user) => {
          if (user) setCurrentUser(user);
        })
        .catch((error) => console.log(error.message));
    };

    if (user) {
      updateCurrentUser();
    }
  }, [user, getAccessTokenSilently]);

  if (isLoading || !currentUser) {
    return <Loading/>;
  }

  return <Redirect to={`/${currentUser.panel_provider}/overview`} />

}

export default LoginSuccess;
