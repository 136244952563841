import React from "react";

const UserModal = ({
  id,
  showModal,
  setShowModal,
  setUserEmail,
  userEmail,
  setUserAdminFlag,
  userAdminFlag,
  onSuccessClick,
  successButtonLabel,
  qmeeAdmin,
  modalCreateMode,
  setQmeeAdmin,
  panelProviderValue,
  setPanelProviderValue,
  isQmeeAdmin
}) => {
  return (
    <div id={id} className={showModal ? "modal is-active" : "modal"}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{modalCreateMode ? 'Create User' : 'Edit User' }</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => setShowModal(false)}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label" htmlFor="user-email">
              Email
            </label>
            <div className="control">
              <input
                id="user-email"
                className="input"
                type="text"
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="example@email.com"
                value={userEmail || ''}
              />
            </div>
          </div>

          {isQmeeAdmin && modalCreateMode  && (
            <div className="field">
              <label className="label" htmlFor="user-email">
                Panel Provider
              </label>
              <div className="control">
                <input
                  id="panel-provider-input"
                  className="input"
                  type="text"
                  onChange={(e) => setPanelProviderValue(e.target.value)}
                  value={panelProviderValue || ''}
                />
              </div>
            </div>
          )}

          {isQmeeAdmin && (
            <div className="field">
              <div className="control">
                <label className="checkbox" htmlFor="qmee-admin-flag">
                  <input
                    id="qmee-admin-flag"
                    style={{ marginRight: "5px" }}
                    checked={qmeeAdmin || false}
                    onChange={(e) => setQmeeAdmin(e.target.checked)}
                    type="checkbox"
                  />
                  Qmee Admin User
                </label>
              </div>
            </div>
          )}

          <div className="field">
            <div className="control">
              <label className="checkbox" htmlFor="user-admin-flag">
                <input
                  id="user-admin-flag"
                  style={{ marginRight: "5px" }}
                  checked={userAdminFlag || false}
                  onChange={(e) => setUserAdminFlag(e.target.checked)}
                  type="checkbox"
                />
                {isQmeeAdmin ? "Provider Admin" : "Admin User"}
              </label>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={onSuccessClick}>
            {successButtonLabel}
          </button>
          <button className="button" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

UserModal.defaultProps = {
  showModal: false,
  setShowModal: ()=>{},
  setUserEmail: ()=>{},
  userEmail: '',
  setUserAdminFlag: ()=>{},
  userAdminFlag: false,
  onSuccessClick: ()=>{},
  successButtonLabel: 'Submit',
  qmeeAdmin: false,
  modalCreateMode: false,
  setQmeeAdmin: ()=>{},
  panelProviderValue: '',
  setPanelProviderValue: ()=>{},
  isQmeeAdmin: false,
};

export default UserModal;
