import axios from "axios";
import { BACKEND_URL } from "./useProviderUserRewardConfigs";

export const getBrackets = async (panelProvider, token) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/portal/manage/provider/${panelProvider}/loi-reward-brackets`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data
};

export const editBracket = async (panelProvider, token, id, reward, loiLimit) => {
  const { data } = await axios.put(
    `${BACKEND_URL}/portal/manage/provider/${panelProvider}/loi-reward-brackets/${id}`,
    {"reward": reward, "loi_limit": loiLimit},
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const deleteBracket = async (panelProvider, token, id) => {
  const { data } = await axios.delete(
    `${BACKEND_URL}/portal/manage/provider/${panelProvider}/loi-reward-brackets/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const createBracket = async (panelProvider, token, reward, loiLimit) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/portal/manage/provider/${panelProvider}/loi-reward-brackets`,
    {"reward": reward, "loi_limit": loiLimit},
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return data;
};
