import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';

const Login = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) { return <Redirect to="/login-success" /> }

  loginWithRedirect();

  return <React.Fragment />;
}
export default Login;