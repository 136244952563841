import React, { useState } from "react";
import { useDeleteProviderUserRewardConfigs } from "../../service/useProviderUserRewardConfigs";
import "./DeleteModal.scss";

const DeleteModal = ({
  onClose,
  provider,
  country,
  getData,
  notify,
  panelProvider,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: deleteUserReward } =
    useDeleteProviderUserRewardConfigs(panelProvider);

  const handleDelete = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const deleteData = getData(provider, country);
        if (typeof deleteData === "undefined")
          throw new Error(
            "The selected user reward config does not exist or is already deleted"
          );
        await deleteUserReward(deleteData);
        notify("Successfully deleted a user reward config");
        setIsLoading(false);
        onClose();
      } catch (err) {
        let errorMessage = err.message;
        if (err.response?.data) {
          errorMessage += `. ${err.response.data}`;
        }
        notify(errorMessage);
        setIsLoading(false);
      }
    }
  };

  const handleClose = () => onClose();

  return (
    <div id="deleteUserRewardsModal">
      <div className="modalBackground" onClick={handleClose} />
      <div className="tile box is-parent is-vertical notification deleteModal">
        <h3>Are you sure you want to delete this user rewards config?</h3>
        <p>Survey provider name - {provider}</p>
        <p>Country code - {country}</p>
        <div className="buttons">
          <button
            className="button is-info"
            type="submit"
            onClick={handleDelete}
          >
            Remove
          </button>

          <button className="button is-info" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteModal.defaultProps = {
  survey_provider_name: "DEFAULT",
  country_code: "DEFAULT",
};

export default DeleteModal;
