import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import PanelistDescription from "../components/Panelists/PanelistDescription";
import { panelistLookup } from "../service/PanelistLookup";
import "./Panelist.scss";
import Loading from "../components/Loading";

const PanelistsPage = (props) => {
  const [searchGuid, setSearchGuid] = useState("");
  const [userDescription, setUserDescription] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const {
    match: { params: { panel_provider: panelProvider = "" } = {} } = {},
  } = props;

  const searchPanelists = async (userGuid) => {
    const authToken = await getAccessTokenSilently();
  
    setError("");
    setUserDescription({});
    setLoading(true);

    const { error, data } = await panelistLookup(panelProvider, userGuid, authToken);

    if (error) {
      setError(`User '${userGuid}' could not be found`);
    } else {
      setUserDescription(data);
    }

    setLoading(false);
  }

  const handleChange = (e) => {
    setSearchGuid(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchGuid && searchGuid.length > 0) {
      searchPanelists(searchGuid);
    } else {
      setUserDescription({});
      setError("");
    }
  };

  return (
    <section className="section">
      <div className="container panelistsPage">
        <div className="pageHeader">
          <h1 className="title">Panelist Lookup</h1>
        </div>
        <div className="panelistLookupContainer">
          <form>
            <input
              type="search"
              id="panelistLookupInput"
              name="user_lookup"
              pattern="[\w\.%/]+"
              className="input"
              title="a-z, A-Z, 0-9, _"
              onChange={handleChange}
              placeholder="Enter user guid"
            />
            <button
              className="button is-info submit"
              type="submit"
              onClick={handleSubmit}
            >
              Search
            </button>
          </form>
        </div>
        {loading &&
          <Loading />
        }
        {userDescription && Object.keys(userDescription).length > 0 && 
          <div className="userDetailsContainer">
            <PanelistDescription userDescription={userDescription} />
          </div>
        }
        {error && 
          <div className="errorMessage">
            <p>{error}</p>
          </div>
        }
      </div>
    </section>
  );
};

export default PanelistsPage;
