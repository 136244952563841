import React, { useContext, useState } from "react";
import "./Sidebar.scss";
import UserContext from "../shared/user-context";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../assets/home-icon.svg";
import { ReactComponent as RewardsIcon } from "../assets/rewards-icon.svg";
import { ReactComponent as UserIcon } from "../assets/user-icon.svg";
import { ReactComponent as Panelists } from "../assets/panelists-icon.svg";
import { ReactComponent as SettingsIcon } from "../assets/settings-icon.svg";
import { ReactComponent as MenuIcon } from "../assets/menu-icon.svg";
import { ReactComponent as InvoiceIcon } from "../assets/invoice-icon.svg";
import { ReactComponent as BarChartIcon } from "../assets/bar-chart.svg";
import { PanelContext } from "../shared/panel-context";

const Sidebar = (props) => {
  const { loading } = props;
  const currentUser = useContext(UserContext);
  const [menuDefaultState, setMenuDefaultState] = useState(true);
  const panelContext = useContext(PanelContext)
  const { pathname } = useLocation();

  const handleMenuClick = () => {
    setMenuDefaultState((prev) => !prev);
  }
  const panelProvider = currentUser?.qmee_admin && panelContext.panel? panelContext.panel : currentUser?.panel_provider;

  return (
    <div>
      <div className={`sidebar-container ${menuDefaultState ? "default" : "changed-state"}`}>
        <button className="minButton" onClick={handleMenuClick}>
          <MenuIcon className="large-icon" />
        </button>
        {!loading && (
          <ul>
            <li>
              <Link
                to={`/${panelProvider}/overview`}
                className={
                  pathname === `/${panelProvider}/overview`
                    ? "is-active nav-item"
                    : "nav-item"
                }
              >
                <HomeIcon className="icon path" />
                <p>Overview</p>
              </Link>
            </li>
            <li>
              <Link
                to={`/${panelProvider}/demographics`}
                className={
                  pathname === `/${panelProvider}/demographics`
                    ? "is-active nav-item"
                    : "nav-item"
                }
              >
                <BarChartIcon className="icon path" />
                <p>Demographics</p>
              </Link>
            </li>
            {!!currentUser && !!currentUser?.provider_admin && (
              <li>
                <Link
                  to={`/${panelProvider}/reward-settings`}
                  className={
                    pathname ===
                    `/${panelProvider}/reward-settings`
                      ? "is-active nav-item"
                      : "nav-item"
                  }
                  title="Manage user reward options and custom currency formatting"
                >
                  <RewardsIcon className="icon stroke" />
                  <p>Rewards</p>
                </Link>
              </li>
            )}
            {!!currentUser && !!currentUser?.provider_admin && (
              <li>
                <Link
                  to={`/${panelProvider}/users`}
                  className={
                    pathname === `/${panelProvider}/users`
                      ? "is-active nav-item"
                      : "nav-item"
                  }
                  title="Invite and manage users of your dashboard"
                >
                  <UserIcon className="icon stroke" />
                  <p>Users</p>
                </Link>
              </li>
            )}
            {!!currentUser && !!currentUser?.provider_admin && (
              <li>
                <Link
                  to={`/${panelProvider}/settings`}
                  className={
                    pathname === `/${panelProvider}/settings`
                      ? "is-active nav-item"
                      : "nav-item"
                  }
                  title="View and manage your API Key"
                >
                  <SettingsIcon className="icon stroke" />
                  <p>Settings</p>
                </Link>
              </li>
            )}
            {!!currentUser && !!currentUser?.panel_provider && (
              <li>
                <Link
                  to={`/${panelProvider}/panel-users`}
                  className={
                    pathname ===
                    `/${panelProvider}/panel-users`
                      ? "is-active nav-item"
                      : "nav-item"
                  }
                  title="Lookup user quality"
                >
                  <Panelists className="icon stroke" />
                  <p>Panelists</p>
                </Link>
              </li>
            )}
            {!!currentUser && panelProvider === "lifepoints" && (
              <li>
                <Link
                  to={`/${panelProvider}/cint-ids`}
                  className={
                    pathname ===
                    `/${panelProvider}/cint-ids`
                      ? "is-active nav-item"
                      : "nav-item"
                  }
                  title="find new cint ID's"
                >
                  <Panelists className="icon stroke" />
                  <p>Cint ID's</p>
                </Link>
              </li>
            )}
            {!!currentUser && !!currentUser?.provider_admin && (
              <li>
                <Link
                  to={`/${panelProvider}/invoice`}
                  className={
                    pathname ===
                    `/${panelProvider}/invoice`
                      ? "is-active nav-item"
                      : "nav-item"
                  }
                  title="View past billing information"
                >
                  <InvoiceIcon className="icon stroke" />
                  <p>Billing</p>
                </Link>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
