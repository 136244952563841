import React, { useState } from "react";
import "./ApiKey.scss";

const CopyButtonIcon = () => {
  return (
    <div className="copyIconContainer">
      <div className="rect" style={{ top: "3px", left: "4px" }}></div>
      <div className="rect dark" style={{ top: "6px", left: "8px" }}></div>
      <div className="rect" style={{ top: "8px", left: "10px" }}></div>
    </div>
  );
};

const ApiKey = ({ apiKey, loading }) => {
  const [showApiKey, setShowApiKey] = useState(false);

  const handleCopyApi = () => {
    if (apiKey) navigator.clipboard.writeText(apiKey);
  };

  const handleApiKeyVisibility = () => setShowApiKey(!showApiKey);

  return (
    <div>
      <h3 className="subtitle is-5">API Key</h3>
      <div className="apiContainer" >
        <div className="apiKeyContainer">
          {showApiKey && (
            <div className="apiKey" >
              <p>{loading && !apiKey ? "Loading..." : apiKey}</p>
              <button
                className="copy"
                onClick={handleCopyApi}
                title="Copy API Key"
              >
                <CopyButtonIcon />
              </button>
            </div>
          )}
        </div>

        <button
          className="button apiVisButton"
          onClick={handleApiKeyVisibility}
          title={`${showApiKey ? "Hide" : "Show"} API Key`}
        >
          {showApiKey ? "Hide" : "Show"}
        </button>
      </div>
    </div>
  );
};

export default ApiKey;
