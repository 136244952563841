import React, { useMemo } from "react";
import "bulma/css/bulma.css";
import {
  useUpdateProviderUserRewardConfigs,
  useGetProviderUserRewardConfigs,
} from "../../service/useProviderUserRewardConfigs";
import { useState } from "react";
import UserRewardsTable from "./UserRewardsTableRow";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateUserRewardsModal from "./CreateModal";
import DeleteModal from "./DeleteModal";

const QmeeUserRewards = ({ panelProvider }) => {
  const [search, setSearch] = useState("");
  const [createRewardsModalVis, setCreateRewardsModalVis] = useState("");
  const [deleteRewardsModalVis, setDeleteRewardsModalVis] = useState("");
  const [selectedData, setSelectedData] = useState({});

  const {
    isError,
    error: userRewarderror,
    data = new Map(),
    refetch,
    isLoading,
  } = useGetProviderUserRewardConfigs(panelProvider);

  const { mutateAsync: updateUserReward } =
    useUpdateProviderUserRewardConfigs(panelProvider);

  const handleSearch = (e) => setSearch(e.target.value);
  const handleCreateModalVis = (bool) => setCreateRewardsModalVis(bool);

  const handleDelete = (provider, country) => {
    if (
      provider.toLowerCase() === "default" &&
      country.toLowerCase() === "default"
    ) {
      notify("You can not delete provider: DEFAULT, country: DEFAULT");
    } else {
      setSelectedData({ provider, country });
      setDeleteRewardsModalVis(true);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteRewardsModalVis(false);
    setSelectedData({});
  };

  const availableProviders = useMemo(() => {
    return [...data.keys()];
  }, [data]);

  const notify = (message = "") => {
    toast.dark(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  //returns true is success or false if request fails
  const onSaveClick = async (data) => {
    try {
      await updateUserReward(data);
      notify(`User reward config successfully changed`);
      return true;
    } catch (err) {
      notify(`Failed to update user reward due to: ${err.message}`);
      return false;
    }
  };

  const getRewardTableData = (provider, country) => {
    return data?.get(provider)?.get(country);
  };

  return (
    <section
      className="section"
      style={{ padding: "0 1.5rem", marginTop: "0" }}
    >
      <ToastContainer />
      <div className="container">
        <div
          className="userRewardsHeader"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <h1 className="title">Offerwall Settings</h1>

          <div>
            <div
              className="field"
              style={{ marginBottom: "20px", textAlign: "end" }}
            >
              <button
                style={{ margin: "auto 0 auto auto" }}
                className="button is-success createButton"
                onClick={() => handleCreateModalVis(true)}
              >
                <span className="plus">+</span>Create
              </button>
            </div>

            <div
              className="field"
              style={{ marginBottom: "20px", textAlign: "end" }}
            >
              <div className="control">
                <input
                  id="searchBar"
                  name="searchBar"
                  className="input searchBar"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {createRewardsModalVis && (
            <CreateUserRewardsModal
              onClose={() => handleCreateModalVis(false)}
              providerKeys={availableProviders}
              data={data}
            />
          )}

          {deleteRewardsModalVis && (
            <DeleteModal
              onClose={handleDeleteModalClose}
              provider={selectedData.provider}
              country={selectedData.country}
              panelProvider={panelProvider}
              getData={getRewardTableData}
              notify={notify}
            />
          )}

          <div className="tile is-ancestor">
            <div
              id="provider-with-country-tags-list"
              className="tile is-parent is-vertical"
            >
              <table>
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Country</th>
                    <th scope="col">Client</th>
                    <th scope="col">Min User Income</th>
                    <th scope="col">Max User Income</th>
                    <th scope="col">Min Random User Income</th>
                    <th scope="col">Max Random User Income</th>
                    <th scope="col">Target Profit Margin</th>
                    <th scope="col">Target Profit Margin Adjustment</th>
                    <th scope="col">Min Profit</th>
                    <th scope="col">Length in Minutes Variance</th>
                    <th scope="col">Median Length in Minutes</th>
                    <th scope="col">Score Variance</th>
                    <th scope="col">Mean Score</th>
                    <th scope="col">High CPI Bonus Threshold</th>
                    <th scope="col">High CPI Bonus Percentage</th>
                    <th scope="col">Post High CPI Bonus Max Reward</th>
                  </tr>
                </thead>
                <tbody className="reward-settings">
                  {!!availableProviders.length &&
                    availableProviders.map((providerName, index) => {
                      const configData = data.get(providerName);
                      const configCountries = configData.keys();
                      return (
                        <React.Fragment key={`${index}-${providerName}`}>
                          {[...configCountries].map((country) => (
                            <UserRewardsTable
                              key={`${index}-${country}${providerName}${configData.get(country).survey_client}`}
                              providerName={providerName}
                              country={country}
                              surveyClient={configData.get(country).survey_client}
                              data={configData.get(country)}
                              onSave={onSaveClick}
                              onDelete={handleDelete}
                              search={search}
                            />
                          ))}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>

              {availableProviders.length === 0 && !isLoading && (
                <div className="noDataError">
                  <p>No items in the table</p>
                  {isError && (
                    <p className="errorMessage">
                      Error: {userRewarderror.message}
                    </p>
                  )}
                  {isError &&
                    userRewarderror.message !== "No panel provider given" && (
                      <button className="button is-info" onClick={refetch}>
                        Retry
                      </button>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QmeeUserRewards;
