import React, { useState } from "react";
import "./Overview.scss";
import GrossRevenueChart from "../components/ProviderStats/GrossRevenueChart";
import ActiveUsersChart from "../components/ProviderStats/ActiveUsersChart";
import StartsPerUserTile from "../components/ProviderStats/StartsPerUserTile";
import PastGrossRevenueTile from "../components/ProviderStats/PastGrossRevenueTile";
import CurrentGrossRevenueTile from "../components/ProviderStats/CurrentGrossRevenueTile";
import ConversionRateTile from "../components/ProviderStats/ConversionRateTile";
import EngagementRateTile from "../components/ProviderStats/EngagementRateTile";
import CompletesChart from "../components/ProviderStats/CompletesCharts";
import EarningsPerClick from "../components/ProviderStats/EarningsPerClickTile";
import ConversionRateChart from "../components/ProviderStats/ConversionRateChart";
import AverageCPICompletionChart from "../components/ProviderStats/AverageCPICompletionsChart";
import MonthlyReconcileChart from "../components/ProviderStats/MonthlyReconcileRateChart";

const intervals = {
  daily: "d",
  monthly: "m",
  hourly: "h",
  weekly: "w",
};

const overviewText = {
  "d": "Daily",
  "h": "Hourly",
  "w": "Weekly",
  "m": "Monthly"
}

const Overview = ({ match }) => {
  const panelProvider = match.params.panel_provider;
  const [interval, setInterval] = useState(intervals[match.params.interval] || 'd');
  const [timeZone, setTimeZone] = useState("");

  const handleTypeChange = (e) => {
    const urlIntervalValue = Object.keys(intervals).find(key => intervals[key] === e.target.value) || 'daily';
    window.history.replaceState(null, '', `/${panelProvider}/overview/${urlIntervalValue}`)
    setInterval(e.target.value);
  }

  const handleTimeZoneChange = (e) => setTimeZone(e.target.value)
  return (
    <section className="section">
      <div className="container">
        <div className="title-container">
          <h1 className="title overview-title">
            {overviewText[interval]} Overview
          </h1>
          <div className="select-container">
          {interval !== "m" && interval !== "w" && (
            <select
              name="timezone select"
              className="timezone"
              value={timeZone}
              onChange={handleTimeZoneChange}
            >
              <option value="UTC">UTC</option>
              <option value="Europe/London">Europe/London</option>
              <option value="America/New_York">America/New York</option>
              <option value="">Current Region</option>
            </select>
          )}
            <select
              className="interval-select"
              value={interval}
              onChange={handleTypeChange}
            >
              <option value="h">Hourly</option>
              <option value="d">Daily</option>
              <option value="w">Weekly</option>
              <option value="m">Monthly</option>
            </select>
          </div>
        </div>

        <div className="tileContainer">
          <CurrentGrossRevenueTile panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
          <PastGrossRevenueTile panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
          <StartsPerUserTile panelProvider={panelProvider} timeZone={timeZone} />
          <EarningsPerClick panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
        </div>
        <div className="graphContainer">
          <GrossRevenueChart panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
          <div className="graphTileContainer">
            <ConversionRateTile panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
            <EngagementRateTile panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
          </div>
          <ActiveUsersChart panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
          <CompletesChart panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
          <ConversionRateChart panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
          <AverageCPICompletionChart panelProvider={panelProvider} interval={interval} timeZone={timeZone} />
          <MonthlyReconcileChart panelProvider={panelProvider} />
          <div className="graphTileContainer" />
        </div>
      </div>
    </section>
  );
};

export default Overview;
