import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

export var PanelContext = React.createContext();

const PanelContextProvider = ({ children }) => {
  const [panel, setPanel] = useState("");
  const history = useHistory();
  const { pathname } = useLocation();

  const updatePanel = (value) => {
    setPanel(value);
    const newLocation = pathname.replace(
      /^\/(?!admin)\w+(?=\/\w+)/,
      '/' + value
    );
    if (newLocation !== pathname) history.push(newLocation);
  };

  useEffect(() => {
    const currentPanel = pathname.match(/^\/(?!admin)(\w+)(?=\/)/i);
    if (currentPanel?.[1]) setPanel(currentPanel?.[1]);
  }, [pathname]);

  return (
    <PanelContext.Provider
      value={{
        updatePanel,
        panel: panel,
      }}
    >
      {children}
    </PanelContext.Provider>
  );
};

export default PanelContextProvider;
