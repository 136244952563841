import axios from "axios";
import { BACKEND_URL } from "./useProviderUserRewardConfigs";

export const convertCintIds = async (
  country,
  panel,
  authToken,
  ids,
  inverse = false
) => {
  const response = await axios.post(
    `${BACKEND_URL}/portal/manage/country/${country}/provider/${panel}/cint-id-lookup?inverse=${inverse}`,
    { ids },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return response.data;
};
