import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { getAverageCPICompletion } from "../../service/ProviderStats";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";
import { usePageVisibility } from "../../service/usePageVisibility";
import {
  getDateString,
  getGraphDateString,
  getTimeString,
} from "../../utils/statsUtils";
import useInterval from "../../service/useInterval";
import useIsMounted from "../../utils/useIsMounted";

const labels = {
  d: "Daily ",
  w: "Weekly ",
  m: "Monthly ",
  h: "Hourly ",
};

const AverageCPICompletionChart = ({ panelProvider, interval = "d", timeZone }) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const isVisible = usePageVisibility();
  const [paused, setPaused] = useState(true);
  const isMounted = useIsMounted();

  const getAverageCPICompletionData = useCallback(async () => {
    const authToken = await getAccessTokenSilently();
    const { data, error } = await getAverageCPICompletion(
      interval,
      panelProvider,
      authToken,
      timeZone
    );
    return { data: data?.data || [], error: error };
  }, [panelProvider, getAccessTokenSilently, interval, timeZone]);

  const handleRetry = () => {
    if (interval === "h") {
      setShouldLoad(true);
      setPaused(true);
    } else {
      const currentInterval = interval;
      setError("");
      setLoading(true);
      getAverageCPICompletionData().then(({ data, error }) => {
        if (currentInterval === interval && isMounted()) {
          setLoading(false);
          setData(data);
          setError(error ? "Average CPI Completions data could not be loaded" : "");
        }
      });
    }
  };

  useInterval(
    () => {
      let mounted = true;
      if (panelProvider && isVisible) {
        setLoading(true);
        getAverageCPICompletionData().then(({ data, error }) => {
          if (mounted) {
            setData(data);
            setLoading(false);
            setShouldLoad(false);
            setError(error ? "Average CPI Completions data could not be loaded" : "");
          }
        });
      } else if (!isVisible) {
        setPaused(true);
      }

      return () => (mounted = false);
    },
    interval === "h" && !paused ? 60000 : null
  );

  useEffect(() => {
    if (timeZone && interval === "h") setPaused(true);
  }, [timeZone, interval]);

  useEffect(() => {
    if (isVisible && paused && interval === "h") setPaused(false);
  }, [isVisible, interval, paused, timeZone]);

  useEffect(() => {
    if (panelProvider && interval !== "h") {
      setShouldLoad(true);
      setError("");
      setLoading(true);
      getAverageCPICompletionData().then(({ data, error }) => {
        if (isMounted()) {
          setLoading(false);
          console.log(data, error)
          setData(data);
          setError(error ? "Average CPI Completions data could not be loaded" : "");
        }
      });
    }
  }, [panelProvider, getAverageCPICompletionData, interval, timeZone, isMounted]);


  const chartOptions = useMemo(() => {
    const orderedActiveUsersData = [...data];

    orderedActiveUsersData.sort((a, b) => a.key > b.key ? 1 : -1);

    return {
      tooltip: {
        formatter: (params) => {
          let nextDate;
          if (interval === "m") {
            nextDate = orderedActiveUsersData[params.dataIndex + 1]
              ? getDateString(orderedActiveUsersData[params.dataIndex + 1].key, 1)
              : "Now";
          }

          return `${params.name}${nextDate ? `- ${nextDate}` : ""}<br /> ${params.data
            } Average CPI Completions data`;
        },
      },
      xAxis: {
        type: "category",
        data: orderedActiveUsersData.map(({ key }) => {
          if (interval === "h") return getTimeString(key).slice(0, 5)
          return getDateString(key);
        }
        ),
        axisLabel: {
          formatter: (value) => getGraphDateString(value, interval),
        },
      },
      yAxis: {
        type: "value",
      },
      grid: { containLabel: true, left: 0, right: 10 },
      series: [
        {
          data: orderedActiveUsersData.map(
            ({ _, value }) => value
          ),
          type: "line",
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#8D4BF3",
              },
              {
                offset: 1,
                color: "#5C5BF7",
              },
            ]),
            borderRadius: [10, 10, 0, 0],
          },
          roundCap: true,
        },
      ],
    };
  }, [interval, data]);

  const dataLoaded = () => {
    return data && data.length > 0;
  };

  return (
    <div className="graphChart">
      <div className="graphHeaderContainer">
        <p className="chartTitle">{labels[interval]}Average Completion CPI</p>
        <button
          className="refreshButton"
          title="Reload Average CPI Completions Chart"
          onClick={handleRetry}
        >
          <RefreshIcon />
        </button>
      </div>

      {error && (
        <p className="errorMessage error">Error loading data: {error}</p>
      )}
      {loading && shouldLoad && (
        <div className="graphMessageContainer">
          <div className="loaderSpinner" />
        </div>
      )}
      {!(loading && shouldLoad) && !error && !dataLoaded() && (
        <div className="graphMessageContainer">
          <p>No Data</p>
        </div>
      )}
      {!(loading && shouldLoad) && !error && dataLoaded() && (
        <ReactEcharts
          option={chartOptions}
          style={{ height: "100%", width: "100%" }}
          className="chart"
        />
      )}
    </div>
  );
};

export default AverageCPICompletionChart;
