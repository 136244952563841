import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./InvoicePage.scss";
import { toast, ToastContainer } from "react-toastify";
import { getAllInvoices } from "../service/Invoice";
import InvoiceRow from "../components/Invoice/InvoiceRow";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function InvoicePage(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { match } = props;
  const panelProvider = match.params.panel_provider;
  const [invoiceData, setInvoiceData] = useState({});
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (panelProvider) {
      (async () => {
        try {
          const authToken = await getAccessTokenSilently();
          const invoiceData = await getAllInvoices(panelProvider, authToken);
          setInvoiceData(invoiceData);
        } catch (err) {
          toast.error("Failed to get invoice: " + err.message);
        }
      })();
    }
  }, [panelProvider, getAccessTokenSilently]);

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  return (
    <section className="section">
      <ToastContainer />
      <div className="container endpointsContainer">
        <div className="userRewardsHeader" >
          <h1 className="title">Billing Information</h1>
        </div>

        <div style={{ textAlign: "right", width:'min(100%, 500px)' }}>
          <select
            className="yearSelect"
            onChange={handleYearChange}
            value={year}
          >
            {Object.keys(invoiceData)
              ?.sort()
              .map((year) => (
                <option val={year} key={year}>
                  {year}
                </option>
              ))}
          </select>
        </div>

        <div className="table-container invoice">
          <table className="table" style={{ width: "100%" }}>
            <tbody>
              {months.map((month, index) => {
                if (
                  !invoiceData?.[year]?.[index + 1] &&
                  new Date(`${year}-${index + 1}`) > new Date()
                ) {
                  return null;
                }
                return (
                  <InvoiceRow
                    month={month}
                    key={`${month} ${year}`}
                    panelProvider={panelProvider}
                    invoiceId={invoiceData?.[year]?.[index + 1]}
                    invoiceDate={`${month} ${year}`}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
