import "./Tile.scss";
import ToolTip from "../ToolTip"
const Tile = ({ data, tileTitle, loading, error, message }) => {
  return (
    <div className="Tile">
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <p className="tileTitle">{tileTitle}</p>
        {message && (<ToolTip message={message}/>)}
      </div>
     
      {error && (
        <p className="error errorMessage">Error loading data: {error}</p>
      )}
      {loading && <div className="loaderSpinner"/>}
      {!loading && !error && <p className="tileValue">{data}</p>}
    </div>
  );
};

export default Tile;
