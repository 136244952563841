import axios from 'axios';

const createNewUser = async (authtoken, payload) => {
  let url = `${process.env.REACT_APP_PROVIDER_API_DOMAIN}/portal/manage/users`;

  const response = await axios.post(
    url, payload, {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )

  const data = response.data
  return data;
}

const updateUser = async (authtoken, userID, payload) => {
  let url = `${process.env.REACT_APP_PROVIDER_API_DOMAIN}/portal/manage/users/${userID}`

  const response = await axios.put(
    url, payload, {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )

  const user = response.data
  return user;
}

const deleteUser = async (authtoken, userID) => {
  let url = `${process.env.REACT_APP_PROVIDER_API_DOMAIN}/portal/manage/users/${userID}`;
  const response = await axios.delete(
    url, {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );

  const data = response.data
  return data
}

const getCurrentUser = async (authtoken) => {
  let url = `${process.env.REACT_APP_PROVIDER_API_DOMAIN}/portal/user-context`;
  const response = await axios.get(
    url, {
        headers: { Authorization: `Bearer ${authtoken}` },
    },
  )
  
  const data = response.data
  return data
};

const getPanelProvidersUsers = async (panelProvider, authtoken) => {
  let url = `${process.env.REACT_APP_PROVIDER_API_DOMAIN}/portal/manage/provider/${panelProvider}/users`;
  const response = await axios.get(
    url, {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )
  const data = response.data
  return data
};

const getUserInviteLink = async (authtoken, userID) => {
  let url = `${process.env.REACT_APP_PROVIDER_API_DOMAIN}/portal/manage/user/${userID}/invite-link`

  const response = await axios.get(
    url, {
        headers: { Authorization: `Bearer ${authtoken}` },
    },
  )
  const inviteLink = response.data
  return inviteLink;
};

export {
  getCurrentUser,
  getPanelProvidersUsers,
  getUserInviteLink,
  createNewUser,
  updateUser,
  deleteUser,
};