import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getProviderRevenue } from "../../service/ProviderStats";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";
import { usePageVisibility } from "../../service/usePageVisibility";
import {
  getDateString,
  getGraphDateString,
  getTimeString,
} from "../../utils/statsUtils";
import useInterval from "../../service/useInterval";
import useIsMounted from "../../utils/useIsMounted";

const labels = {
  d: "Daily ",
  w: "Weekly ",
  m: "Monthly ",
  h: "Hourly ",
};

const GrossRevenueChart = ({ panelProvider, interval = "d", timeZone }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const isVisible = usePageVisibility();
  const [paused, setPaused] = useState(true);
  const isMounted = useIsMounted();

  const getRevenueData = useCallback(async () => {
    const authToken = await getAccessTokenSilently();
    const { data, error } = await getProviderRevenue(
      interval,
      panelProvider,
      authToken,
      timeZone
    );

    return { data: data?.data || [], error: error };
  }, [panelProvider, getAccessTokenSilently, interval, timeZone]);

  useInterval(
    () => {
      let mounted = true;

      if (panelProvider && isVisible) {
        setLoading(true);
        getRevenueData().then(({ data, error }) => {
          if (mounted) {
            setData(data);
            setLoading(false);
            setError(error ? "Revenue data could not be loaded" : "");
            setShouldLoad(false);
          }
        });
      } else if (!isVisible) {
        setPaused(true);
      }

      return () => (mounted = false);
    },
    interval === "h" && !paused ? 60000 : null
  );

  useEffect(() => {
    if (panelProvider && interval !== "h") {
      setError("");
      setLoading(true);
      setShouldLoad(true);
      getRevenueData().then(({ data, error }) => {
        if (isMounted()) {
          setLoading(false);
          setData(data);
          if (error) setError(error ? "Revenue data could not be loaded" : "");
        }
      });
    }
  }, [panelProvider, getRevenueData, interval, timeZone, isMounted]);

  const handleRetry = async () => {
    if (interval === "h") {
      setPaused(true);
      setShouldLoad(true);
    } else {
      let currentInterval = interval;
      setLoading(true);
      setError("");
      const { data, error } = await getRevenueData(true);
      if (currentInterval === interval && isMounted()) {
        setData(data);
        setLoading(false);
        if (error) setError("Revenue data could not be loaded");
      }
    }
  };

  useEffect(() => {
    if (isVisible && paused && interval === "h") setPaused(false);
  }, [isVisible, interval, paused, timeZone]);

  const chartOptions = useMemo(() => {
    const orderedRevenueData = [...data];

    orderedRevenueData.sort((a, b) => a.key > b.key ? 1 : -1);

    return {
      tooltip: {
        formatter: (params) => {
          let nextDate;
          if (interval === "m") {
            nextDate = orderedRevenueData[params.dataIndex + 1]
              ? getDateString(orderedRevenueData[params.dataIndex + 1].key, 1)
              : "Now";
          }

          return `${params.name}${nextDate ? `- ${nextDate}` : ""}<br /> $${
            params.data/100
          }`;
        },
      },
      grid: { containLabel: true, left: 0, right: 10 },
      xAxis: {
        type: "category",
        data: orderedRevenueData.map(({ key }) => {
          if (interval === "h") return getTimeString(key).slice(0,5)
          return getDateString(key);
        }
        ),
        axisLabel: {
          formatter: (value) => getGraphDateString(value, interval),
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value)=> value/100,
        },
      },
      series: [
        {
          data: orderedRevenueData.map(({ value }) => (+(value || 0))?.toFixed(2)),
          type: "bar",
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#8D4BF3",
              },
              {
                offset: 1,
                color: "#5C5BF7",
              },
            ]),
            borderRadius: [10, 10, 0, 0],
          },
          roundCap: true,
        },
      ],
    };
  }, [data, interval]);

  const dataLoaded = () => {
    return data && data.length > 0;
  };

  return (
    <div className="graphChart">
      <div className="graphHeaderContainer">
        <p className="chartTitle">{labels[interval]}Gross Revenue</p>
        <button
          className="refreshButton"
          title="Reload Gross Revenue chart"
          onClick={handleRetry}
        >
          <RefreshIcon />
        </button>
      </div>
      {error && (
        <p className="errorMessage error">Error loading data: {error}</p>
      )}
      {loading && shouldLoad && (
        <div className="graphMessageContainer">
          <div className="loaderSpinner"/>
        </div>
      )}
      {!(loading && shouldLoad) && !error && !dataLoaded() && (
        <div className="graphMessageContainer">
          <p>No Data</p>
        </div>
      )}
      {!(loading && shouldLoad) && !error && dataLoaded() && (
        <ReactEcharts
          option={chartOptions}
          style={{ height: "100%", width: "100%" }}
          className="chart"
        />
      )}
    </div>
  );
};

export default GrossRevenueChart;
