import axios from "axios";
import { BACKEND_URL } from "./useProviderUserRewardConfigs";

export const getProviderSettings = async (panelProvider, authToken) => {
  let providerSettings = {};
  let apiKey = "";
  let error;
  let name;
  try {
    const response = await axios.get(
      `${BACKEND_URL}/portal/manage/panelprovider/${panelProvider}`,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );

    const { data } = response;
  
    apiKey = data.api_key;
    name = data.name;
    providerSettings = {
      reward_calculator: data.settings.reward_calculator,
      user_revenue_share: data.settings.user_revenue_share * 100,
      custom_currency_format: data.settings.custom_currency_format,
      currency_conversion_rate: data.settings.currency_conversion_rate,
      use_new_user_surveys: data.settings.use_new_user_surveys
    };
  } catch (err) {

    error = err;
  }

  return { providerSettings, apiKey, error, name };
};

export const updateProviderSettings = async (
  payload,
  panelProvider,
  authToken
) => {
  let error = "";
  let responseData = {};
  try {
    const response = await axios.put(
      `${BACKEND_URL}/portal/manage/panelprovider/${panelProvider}`,
      {
        reward_calculator: payload.reward_calculator,
        user_revenue_share: payload.user_revenue_share / 100,
        custom_currency_format: payload.custom_currency_format,
        custom_currency_conversion_rate: payload.currency_conversion_rate,
        use_new_user_surveys: payload.use_new_user_surveys
      },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

    const { data } = response;
    responseData = data
  } catch (err) {
    error = err;
  }
  return { error, data: responseData };
};
