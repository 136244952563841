import React, { useCallback, useEffect, useRef, useState } from "react";
import "./SplitResultsInput.scss";

const CANVAS = document.createElement("canvas");

function getTextWidth(text, font) {
  const context = CANVAS.getContext("2d");
  context.font = font;
  return context.measureText(text).width;
}

const SplitResultsInput = ({ defaultValue, handleSplitChange }) => {
  const [value, setValue] = useState(defaultValue)
  const suffixRef = useRef();
  const inputRef = useRef();
  const prefixRef = useRef();

  const changeSuffixPosition = useCallback(() => {
    const width = getTextWidth("\"" + inputRef.current.value, "12px arial");
    suffixRef.current.style.left = width + "px";
    const inputWidth = inputRef.current.clientWidth - 6;
    if(width > inputWidth){
        prefixRef.current.style.left = inputWidth - width - 3 + "px";
    } else {
        prefixRef.current.style.left = "0px";
    }
  }, [])

  const handleChange = async (e) => {
    setValue(e.target.value);
    changeSuffixPosition()
   
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') changeSuffixPosition();
  }

  useEffect(()=>{
    changeSuffixPosition();
  },[changeSuffixPosition]);

  const handleSubmit = () =>{
    handleSplitChange(value)
  }
  

  return (
    <div className="split-cint-ids-container">
      <p>Split up results by: </p>
      <div className="split-cint-ids-input">
        <span ref={prefixRef} className="split-result-prefix">"</span>
        <input
          ref={inputRef}
          type="text"
          onChange={handleChange}
          value={value}
          onKeyDown={handleKeyDown}
          onBlur={handleSubmit}
        />
        <span className="split-result-suffix" ref={suffixRef}>
          "
        </span>
      </div>
    </div>
  );
};

export default SplitResultsInput;
