import React, { memo } from "react";
import {
  CONFIG_FIELD_KEYS,
  CONFIG_FIELD_LABELS,
} from "./UserRewardConfigFields";
import PropTypes from "prop-types";

const CreateModalConfigOptions = ({
  fields,
  onFieldChange,
  required,
  number: isNumber,
}) => {
  return (
      <ul className="configOptionsContainer">
        {CONFIG_FIELD_KEYS.map((configFieldKey) => {
          const value = !fields[configFieldKey] && fields[configFieldKey] !== 0 ? '' : fields[configFieldKey];
          return(
          <li key={configFieldKey} className="field">
            <label className="label" htmlFor={`${configFieldKey}_field`}>
              {CONFIG_FIELD_LABELS[configFieldKey].name}
            </label>
            
            {isNumber && (
                <p className="descriptorInfo">Max: {String(CONFIG_FIELD_LABELS?.[configFieldKey]?.max)}, Min: {String(CONFIG_FIELD_LABELS?.[configFieldKey]?.min)}</p>
            )}
            <div className="control">
              <input
                id={`${configFieldKey}_field`}
                name={configFieldKey}
                className="input"
                min={
                  typeof CONFIG_FIELD_LABELS?.[configFieldKey]?.min !== "undefined"
                    ? String(CONFIG_FIELD_LABELS?.[configFieldKey]?.min)
                    : undefined
                } 
                max={
                  typeof CONFIG_FIELD_LABELS?.[configFieldKey]?.max !== "undefined"
                    ? String(CONFIG_FIELD_LABELS?.[configFieldKey]?.max)
                    : undefined
                }
                title={`Please enter a number between ${CONFIG_FIELD_LABELS?.[configFieldKey]?.min} and ${CONFIG_FIELD_LABELS?.[configFieldKey]?.max}`}
                type={isNumber ? "number" : "text"}
                value={value}
                onChange={onFieldChange}
                required={!!required}
                step="any"
              />
            </div>
          </li>
        )})}
      </ul>
  );
};

CreateModalConfigOptions.propTypes = {
  fields: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func,
  required: PropTypes.bool,
  number: PropTypes.bool,
};

CreateModalConfigOptions.defaultProps = {
  fields: "",
  onFieldChange: () => {},
  required: false,
  number: false,
};

export default memo(CreateModalConfigOptions);
