import React, { useRef, useEffect } from "react";
import "./Tooltip.scss";
import { ReactComponent as InfoIcon } from "../assets/info-icon.svg";

const ToolTip = ({ message }) => {
  const messageRef = useRef();
  const toolTipRef = useRef();

  const positionMessage = () => {
    if(!messageRef.current ||  !toolTipRef.current) return;

    var message = messageRef.current;
    var icon_rect = toolTipRef.current.getBoundingClientRect();
    var message_rect = message.getBoundingClientRect();

    var tipY = -10;
    message.style.top = tipY + "px";

    if (window.innerWidth < 600) {
      message.style.right = "0%";
      message.style.left = "auto";
      message.style.top = "105%";
      return;
    }

    const messageOverflowing =
      icon_rect.right + message_rect.width >= window.innerWidth;

    message.style.left = messageOverflowing ? "" : "105%";
    message.style.right = messageOverflowing ? "105%" : "";
  };

  useEffect(() => {
    positionMessage();
    window.addEventListener("resize", positionMessage);
    window.addEventListener("orientationchange", positionMessage);
    return () => {
      window.removeEventListener("resize", positionMessage);
      window.removeEventListener("orientationchange", positionMessage);
    };
  }, [toolTipRef, messageRef]);

  return (
    <div 
      ref={toolTipRef} 
      className="tooltip"
      onMouseEnter={positionMessage}
      onTouchStart={positionMessage}
    >
      <InfoIcon className="icon"/>
      <span ref={messageRef} className="tooltiptext">
        {message}
      </span>
    </div>
  );
};

export default ToolTip;
