import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "table",
    width: "auto",
  },
  totalTable: {
    width: "auto",
    margin: " 0 0 10 0",
    display: "table",
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1 solid #eeeeee",
  },
  totalTableRow: {
    flexDirection: "row",
    paddingLeft: 150,
  },
  totalTableCol: {
    width: "25%",
    border: "none",
  },
  totalTableHeaderCol: {
    width: "25%",
    border: "none",
    backgroundColor: "#eeeeee",
  },
  tableRowHeader: {
    flexDirection: "row",
    alignItems: "center",
    border: "none",
    backgroundColor: "#eeeeee",
  },
  tableCol: {
    width: "17%",
    border: "none",
  },
  tableHeaderCol: {
    width: "17%",
    border: "none",
    backgroundColor: "#eeeeee",
  },
  tableCell: { margin: "auto", marginTop: 2, fontSize: 10, marginBottom: 2 },
  tableHeaderCell: {
    margin: "auto",
    marginTop: 2,
    fontSize: 11,
    marginBottom: 2,
    fontWeight: 600,
  },
  totalCell: { marginTop: 2, marginLeft: 20, fontSize: 10, marginBottom: 2 },
  address: {
    fontSize: 10,
    color: "grey",
  },
  flex: {
    fontSize: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
  },
  border: {
    borderBottom: "1 solid black",
    padding: 20,
    alignSelf: "flex-end",
  },
  image: {
    height: 25,
    width: 25,
    margin: "auto",
  },
  subtotal: {
    backgroundColor: "#eeeeee",
    fontWeight: 500,
  },
});

const TableRow = ({ item, index, runningTotal }) => {
  return (
    <View style={styles.tableRow} key={index}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{item?.date}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{item?.completes}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{FormatDollars(item.revenue)}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{item?.reconciles}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{FormatDollars(item.net_reconciles)}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{FormatDollars(runningTotal)}</Text>
      </View>
    </View>
  );
};

const InvoicePDF = ({ invoice, invoiceDate }) => {
  const totalRevenue = (
    invoice?.line_items?.reduce((previous, current) => 
        previous + (current.revenue || 0) + (current.net_reconciles || 0), 0) / 100
  )?.toFixed(2);

  const totalCompletes = invoice?.line_items?.reduce((previous, current) => {
    if (current.completes) {
      return previous + current.completes;
    }
    return previous;
  }, 0);

  const totalReconciles = invoice?.line_items?.reduce((previous, current) => {
    if (current.reconciles) {
      return previous + current.reconciles;
    }
    return previous;
  }, 0);

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          Billing Information for{" "}
          {invoice?.panel_provider.charAt(0).toUpperCase() +
            invoice?.panel_provider.slice(1)}{" "}
          - {invoiceDate}
        </Text>
        <View style={styles.address}>
          <Text style={{ margin: "0 0 5 0" }}>Please raise invoice to:</Text>
          <Text>Qmee.Limited</Text>
          <Text>Work.Life - The White Building</Text>
          <Text>33 King's Road</Text>
          <Text>Reading</Text>
          <Text>Berkshire</Text>
          <Text>RG1 3AR</Text>
          <Text>England</Text>
        </View>

        <View style={[styles.address, { margin: "5 0 0" }]}>
          <Text>finance@qmee.com</Text>
          <Text>VAT number - GB388154659</Text>
        </View>

        <View style={styles.totalTable}>
          <View style={styles.totalTableRow}>
            <View style={styles.totalTableCol} />
            <View style={styles.totalTableCol} />
            <View style={styles.totalTableCol}>
              <Text style={styles.totalCell}>Invoice For:</Text>
            </View>
            <View style={styles.totalTableCol}>
              <Text style={styles.totalCell}>{invoiceDate}</Text>
            </View>
          </View>
          <View style={styles.totalTableRow}>
            <View style={styles.totalTableCol} />
            <View style={styles.totalTableCol} />
            <View style={styles.totalTableCol}>
              <Text style={styles.totalCell}>Completes:</Text>
            </View>
            <View style={styles.totalTableCol}>
              <Text style={styles.totalCell}>{`${totalCompletes}`}</Text>
            </View>
          </View>
          <View style={styles.totalTableRow}>
            <View style={styles.totalTableCol} />
            <View style={styles.totalTableCol} />
            <View style={styles.totalTableCol}>
              <Text style={styles.totalCell}>Reconciles:</Text>
            </View>
            <View style={styles.totalTableCol}>
              <Text style={styles.totalCell}>{`${totalReconciles}`}</Text>
            </View>
          </View>
          <View style={styles.totalTableRow}>
            <View style={styles.totalTableCol}></View>
            <View style={styles.totalTableCol}></View>
            <View style={styles.totalTableHeaderCol}>
              <Text style={styles.totalCell}>Total:</Text>
            </View>
            <View style={styles.totalTableHeaderCol}>
              <Text style={styles.totalCell}>
                {`$${totalRevenue || "0.00"}`}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRowHeader}>
            <View style={styles.tableHeaderCol}>
              <Text style={styles.tableHeaderCell}>Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeaderCell}>Completes</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeaderCell}>Revenue</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeaderCell}>Reconciles</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeaderCell}>Net Reconciles</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeaderCell}>Subtotal</Text>
            </View>
          </View>

          {invoice?.line_items?.map(
            ((sum) => (item, index) => {
              sum += item?.revenue + item?.net_reconciles;
              return (
                <TableRow
                  key={index}
                  item={item}
                  index={index}
                  runningTotal={sum}
                />
              );
            })(0)
          )}
        </View>

        <View style={styles.footer} fixed>
          <Image style={styles.image} src={window.location.origin + '/qurated.png'} />
          <Text style={[styles.header, { marginTop: 5 }]}>Qurated</Text>
        </View>
      </Page>
    </Document>
  );
};

const FormatDollars = (value) => {
  var sign = value < 0 ? '-' : '';
  return `${sign}$${(Math.abs(value) / 100).toFixed(2)}`;
}

export default InvoicePDF;
