import React, { useState, useContext } from "react";
import CreateModalConfigOptions from "./CreateModalConfigOptions";
import PropTypes from "prop-types";
import { useCreateProviderUserRewardConfigs } from "../../service/useProviderUserRewardConfigs";
import UserContext from "../../shared/user-context";
import "./CreateModal.scss";
import { notify } from "../../utils/notify";

export const CreateUserRewardsModal = ({
  onClose,
  data,
  providerKeys,
}) => {
  const [inputData, setInputData] = useState({});
  const [isLoading, setLoading] = useState(false);

  const currentUser = useContext(UserContext)
  const { panel_provider = "" } = currentUser || {};


  const { mutateAsync: createUserReward } = useCreateProviderUserRewardConfigs(panel_provider)
  const changeCallback = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };


  const handleClose = () => {
    setInputData({});
    onClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setLoading(true);
      if (
        data?.get(inputData.survey_provider_name)?.get(inputData.country_code)
      ) {
        notify(
          "Data is already provided for that provider name and country code"
        );
        setLoading(false);
        return;
      }
      try {
        await createUserReward(inputData);
        notify("successfully created user rewards");
        setLoading(false);
        handleClose();
      } catch (err) {
        setLoading(false);
        notify(`Failed to create user rewards due to: ${err.message}`);

      }
    }
  };

  const handleImport = (e) => {
    if (e.target.value === "") return;
    // choose and import preset data
    const array = e.target.value.split(" ");
    const chosenData = data?.get(array[0])?.get(array[1]);

    //sets the data but doesn't change the names or providers
    setInputData({
      ...chosenData,
      id: undefined,
      survey_provider_name: inputData.survey_provider_name || "",
      country_code: inputData.country_code || "",
    });
  };

  return (
    <div id="userRewardsModal">
      <div id="modalBackground" onClick={handleClose} />
      <div className="tile box is-parent is-vertical notification createFormContainer">
        <form onSubmit={handleSave}>
          <h3>Create a new User Rewards config</h3>
          <div className="field">
            <label className="label" htmlFor="provider_name_field">
              Provider Name
            </label>
            <div className="control">
              <input
                id="provider_name_field"
                name="survey_provider_name"
                className="input"
                type="text"
                value={inputData["survey_provider_name"] || ""}
                placeholder="Survey provider name"
                pattern={"^\\w+$"}
                onChange={changeCallback}
                title="Any letter, number or _"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="country_code_field">
              Country Code
            </label>
            <div className="control">
              <input
                id="country_code_field"
                name="country_code"
                className="input"
                type="text"
                required
                value={inputData["country_code"] || ""}
                title="Please enter 3 Capital Letters or DEFAULT"
                onChange={changeCallback}
                placeholder="3 letter country code or DEFAULT"
                pattern={"^([A-Z]{3}||DEFAULT)$"}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="survey_client_field">
              Survey Client
            </label>
            <div className="control">
              <input
                id="survey_client_field"
                name="survey_client"
                className="input"
                type="text"
                required
                value={inputData["survey_client"] || "DEFAULT"}
                title="Enter the survey client name or  DEFAULT"
                onChange={changeCallback}
                placeholder="survey client name or DEFAULT"
                pattern={"^\\w+$"}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="providerPreset">
              Import Preset Data From
            </label>
            <div className="control select">
              <select name="" id="providerPreset" onChange={handleImport} defaultValue="">
                <option value="" key="none" disabled>
                  None
                </option>

                {providerKeys?.map((providerName, index) => {
                  const configCountries = data?.get(providerName)?.keys();
                  return (
                    <React.Fragment key={index}>
                      {[...configCountries].map((country) => (
                        <option
                          value={`${providerName} ${country}`}
                          key={`${index}${providerName}${country}`}
                        >
                          {providerName} {country}
                        </option>
                      ))}
                    </React.Fragment>
                  );
                })}
              </select>
            </div>
          </div>

          <CreateModalConfigOptions
            onFieldChange={changeCallback}
            fields={inputData}
            required={true}
            number={true}
          />
          <div className="buttonsContainer">
            <button
              className="button is-info"
              type="submit"
            >
              Save
            </button>

            <button
              className="button is-info"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

CreateUserRewardsModal.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
  providerKeys: PropTypes.arrayOf(PropTypes.string),
};

CreateUserRewardsModal.defaultProps = {
  providerKeys: [],
  data: new Map(),
  onClose: () => { },
};

export default CreateUserRewardsModal;
