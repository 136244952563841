import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { CONFIG_FIELD_KEYS } from "./UserRewardConfigFields";
import TableDataInput from "./TableDataInput";
import "./UserRewardsTableRow.scss";
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';

export const UserRewardsTable = ({
  providerName,
  country,
  surveyClient,
  data,
  onSave,
  onDelete,
  search,
}) => {
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [changedData, setChangedData] = useState({});
  const [show, setShow] = useState(true);

  const handleSearch = useCallback(() => {
    if (
      !`${providerName.toLowerCase()} ${country.toLowerCase()} ${surveyClient.toLowerCase()}`.includes(
        search.toLowerCase()
      )
    ) {
      setShow(false);
    } else if (!show) {
      setShow(true);
    }
  }, [search, providerName, country, surveyClient, show]);

  useEffect(() => {
    if (saveEnabled) setChangedData(data);
  }, [saveEnabled, data]);

  useEffect(() => {
    handleSearch();
  }, [search, providerName, country, handleSearch]);

  const handleChange = (value, name) => {
    setChangedData({
      ...changedData,
      [name]: value,
    });
  };

  const handleSave = async () => {
    const successfulResponse = await onSave(changedData);
    if (successfulResponse) setSaveEnabled(false);
  };

  const handleReset = () => {
    setChangedData(data);
    setSaveEnabled(false);
  };
  const handleDelete = () => {
    onDelete(providerName, country);
  };

  return (
    <tr
      style={{ display: show ? "" : "none" }}
      key={`${providerName}${country}${surveyClient}`}
    >
      <td data-label="Provider Name">{data.survey_provider_name}</td>
      <td data-label="Country">{data.country_code}</td>
      <td data-label="Client">{data.survey_client}</td>
      {CONFIG_FIELD_KEYS.map((key, index) => (
        <TableDataInput
          key={index}
          originalVal={data[key]}
          value={changedData[key]}
          onChange={handleChange}
          dataKey={key}
          editMode={saveEnabled}
        />
      ))}

      <td>
        <div className="tableButtonContainer">
          {!saveEnabled ? (
            <button
              className="button editButton"
              onClick={() => setSaveEnabled(true)}
              key="edit"
            >
              Edit
            </button>
          ) : (
            <button
              key="save"
              className="button saveButton "
              onClick={handleSave}
            >
              Save
            </button>
          )}
        </div>
      </td>
      <td>
        <div className="tableButtonContainer">
          {!saveEnabled ? (
            <button className="button deleteButton" onClick={handleDelete}>
              <DeleteIcon className="icon" />
            </button>
          ) : (
            <button
              key={"cancel"}
              className="button resetButton"
              onClick={handleReset}
            >
              Cancel
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

UserRewardsTable.propTypes = {
  providerName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  surveyClient: PropTypes.string.isRequired,
  data: PropTypes.object,
  onSave: PropTypes.func,
  search: PropTypes.string,
};

UserRewardsTable.defaultProps = {
  search: "",
  data: new Map(),
  onSave: () => { },
};

export default UserRewardsTable;
