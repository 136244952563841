import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getIncomeBreakdown } from "../../service/ProviderStats";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";

const sortIncome = (income) => {
  return income?.sort((a, b) => {
    const regex = /\d+(,\d+)*/g;
    if (a?.key === "Prefer not to say") return -1;
    if (b?.key === "Prefer not to say") return 1;
    if (a?.key === "< $15,000") return -1;
    if (b?.key === "< $15,000") return 1;
    const aAmount = +a.key?.match(regex)?.[0]?.replace(",", "");
    if (!aAmount) return -1;
    const bAmount = +b.key?.match(regex)?.[0]?.replace(",", "");
    if (!bAmount) return 1;
    return aAmount - bAmount;
  });
};

const IncomeBreakdown = ({ panelProvider }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const getData = useCallback(async () => {
    setError("");
    setLoading(true);

    const authToken = await getAccessTokenSilently();
    const { data, error } = await getIncomeBreakdown(panelProvider, authToken);
    setLoading(false);

    const income = sortIncome(data?.data);
    return { data: income || [], error: error };
  }, [panelProvider, getAccessTokenSilently]);

  useEffect(() => {
    let mounted = true;
    if (panelProvider) {
      getData().then(({ data, error }) => {
        if (mounted) {
          setData(data);
          if (error) setError("Income data could not be loaded");
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [panelProvider, getData]);

  const handleRetry = async () => {
    const { data, error } = await getData(true);
    setData(data);
    if (error) setError("Income data could not be loaded");
  };

  const getChartOptions = (genderBreakdownData) => {
    const max = genderBreakdownData.reduce(
      (acum, curr) => 1 * acum + 1 * curr.value,
      0
    );
    return {
      tooltip: {
        trigger: "axis",
        formatter: (params) =>
          `${params?.[0].name} </br> ${params?.[0].value} Users </br> ${(
            (params?.[0].value / max) *
            100
          )?.toFixed(2)}%`,
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        containLabel: true,
        left: 0,
        right: 0,
      },
      yAxis: {
        type: "category",
        data: genderBreakdownData.map((val) => val?.key),
        axisLabel: {
          width: 120, //fixed number of pixels
          overflow: "truncate",
          interval: 0,
        },
      },
      xAxis: {
        type: "value",
        axisLabel: {
          hideOverlap: true,
        },
      },
      series: [
        {
          data: genderBreakdownData.map((value) => {
            return {
              value: 1 * value?.value,
              name: value?.key,
            };
          }),
          name: "Ethnicity Breakdown",
          type: "bar",
          avoidLabelOverlap: false,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#8D4BF3",
              },
              {
                offset: 1,
                color: "#5C5BF7",
              },
            ]),
            borderRadius: [0, 5, 5, 0],
          },
          roundCap: true,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: false,
            },
          },
        },
      ],
    };
  };

  const dataLoaded = () => {
    return data && data.length > 0;
  };

  return (
    <div className="graphChart" style={{ height: "800px" }}>
      <div className="graphHeaderContainer">
        <p className="chartTitle">User Income Breakdown</p>
        <button
          className="refreshButton"
          title="Reload Gross Revenue chart"
          onClick={handleRetry}
        >
          <RefreshIcon />
        </button>
      </div>
      {error && (
        <p className="errorMessage error">Error loading data: {error}</p>
      )}
      {loading && (
        <div className="graphMessageContainer" style={{ height: "800px" }}>
          <div className="loaderSpinner"/>
        </div>
      )}
      {!loading && !error && !dataLoaded() && (
        <div className="graphMessageContainer">
          <p>No Data</p>
        </div>
      )}
      {!error && dataLoaded() && (
        <ReactEcharts
          option={getChartOptions(data)}
          style={{ height: "100%", width: "100%" }}
          className="chart"
        />
      )}
    </div>
  );
};

export default IncomeBreakdown;
