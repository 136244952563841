import React from "react";
import { ToastContainer } from "react-toastify";
import "./SettingsPage.scss";
import Endpoints from "../components/Endpoints/Endpoints";
import Settings from "../components/ApiKey/Settings";

const SettingsPage = (props) => {
  const { match } = props;
  const { panel_provider: panelProvider } = match.params;

  return (
    <section className="section settingsPage">
      <ToastContainer />
      <div className="container endpointsContainer">
        <div className="userRewardsHeader">
          <h1 className="title">Settings</h1>
        </div>
        <Settings panelProvider={panelProvider}/>
        <Endpoints panelProvider={panelProvider}/>
      </div>
    </section>
  );
};

export default SettingsPage;
