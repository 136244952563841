import axios from 'axios';
import { BACKEND_URL } from "./useProviderUserRewardConfigs";

const panelistLookup = async (panelProvider, panelistGuid, authToken) => {
  let error = "";
  let data = {};

  try {
    const response = await axios.get(
      `${BACKEND_URL}/portal/provider/${panelProvider}/user-lookup/${panelistGuid}`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

    data = response.data;
  } catch (err) {
    error = err;
  } finally {
    return { error, data };
  }
};

export {
  panelistLookup
};