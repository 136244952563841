import { useEffect, useRef } from "react";

//A hook to clean up code for setInterval
export default function useInterval(callback, delay) {
  const savedCallback = useRef();
  const unmountCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (unmountCallback.current) {
        unmountCallback.current();
      }
      unmountCallback.current = savedCallback.current();
    }
    if (delay !== null) {
      unmountCallback.current = savedCallback.current();
      let id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
        if (unmountCallback.current) unmountCallback.current();
      };
    }
  }, [delay]);
}
