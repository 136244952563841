import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { convertCintIds } from "../service/CintIds";
import "./CintIdPage.scss";
import ResultText from "../components/CintIDPage/ResultText";
import ResultJSON from "../components/CintIDPage/ResultJSON";
import SplitResultsInput from "../components/CintIDPage/SplitResultsInput";

const SUPPORTED_COUNTRIES = {
  esp: "Spain",
  irl: "Ireland",
  can: "Canada"
};

const CintIdPage = ({ match }) => {
  const panelProvider = match.params.panel_provider;
  const [cintIds, setCintIds] = useState("");
  const [resultIds, setResultIds] = useState("");
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [country, setCountry] = useState();
  const [error, setError] = useState("");
  const [invalidIds, setInvalidIds] = useState([]);
  const [resultSplit, setResultSplit] = useState(", ");
  const [inverseCintIDlookup, setInverseCintIDLookup] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const requestList = cintIds.split(/[\n ,]+/g);
      const authToken = await getAccessTokenSilently();

      const data = await convertCintIds(
        country,
        panelProvider,
        authToken,
        requestList,
        inverseCintIDlookup
      );
      const validIDs = data?.ids;
      const invalidIDs = data?.invalid_ids;

      setResultIds(validIDs);
      setInvalidIds(invalidIDs);
      setShowResults(true);
      setLoading(false);
    } catch (err) {
      toast.error("Failed to get Cint ID's: " + err.message);
      setLoading(false);
      setError(err.message);
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleChange = (e) => {
    setCintIds(e.target.value);
  };

  const handleBack = () => {
    setShowResults(false);
  };

  const handleInverseUserLookup = (e) => {
    setInverseCintIDLookup(e.target.value === "true");
  };

  const handleSplitChange = (value) => {
    setResultSplit(value);
  };

  return (
    <section className="section">
      <ToastContainer />
      <div className="container">
        <div className="title-container">
          <h1 className="title">Cint ID's</h1>
        </div>
        <div>
          {!showResults ? (
            <form>
              <h3 style={{ float: "left" }}>
                Enter a list of {inverseCintIDlookup ? "New" : "Old"} Cint ID's
              </h3>
              <div className="cint-id-options-container">
                <div className="cint-id-option">
                  <label htmlFor="cint-id-inverse">Find: </label>
                  <select
                    name="inverse-user-lookup"
                    id="cint-id-inverse"
                    value={inverseCintIDlookup}
                    onChange={handleInverseUserLookup}
                    required
                  >
                    <option value={false}>New Cint ID's</option>
                    <option value={true}>Old Cint ID's</option>
                  </select>
                </div>

                <div className="cint-id-option">
                  <label htmlFor="cint-id-country">Country: </label>
                  <select
                    name="country"
                    id="cint-id-country"
                    value={country}
                    onChange={handleCountryChange}
                    defaultValue=""
                    required
                  >
                    <option value="" disabled defaultChecked>
                      Select a country
                    </option>
                    {Object.keys(SUPPORTED_COUNTRIES).map((country_code) => (
                      <option key={country_code} value={country_code}>
                        {SUPPORTED_COUNTRIES[country_code]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <textarea
                className="cint-id-textbox"
                cols="30"
                rows="10"
                placeholder="Cint ID's..."
                value={cintIds}
                onChange={handleChange}
                required
              ></textarea>
              <button
                type="submit"
                className="button is-info cint-id-submit-button"
                onClick={handleSubmit}
                disabled={loading || !country || !cintIds}
              >
                Submit
              </button>
              {error && (
                <div className="errorContainer">
                  <p className="errorMessage">
                    Failed to get Cint ID's: {error}
                  </p>
                </div>
              )}
            </form>
          ) : (
            <div>
              <button
                title="go back"
                id="cint-id-back-button"
                className="button is-info"
                onClick={handleBack}
              >
                Back
              </button>
              {resultIds && (
                <div>
                  <div>
                    <h3 className="cint-id-header">Country</h3>
                    <p className="cint-id-subtext">
                      {SUPPORTED_COUNTRIES[country]}
                    </p>
                  </div>

                  <SplitResultsInput
                    defaultValue=", "
                    handleSplitChange={handleSplitChange}
                  />
                  <ResultText
                    text={Object.values(resultIds)?.join(resultSplit || "\n")}
                    header={`${inverseCintIDlookup ? "Old" : "New"} Cint ID's`}
                  />

                  {!!invalidIds?.length && (
                    <ResultText
                      text={invalidIds.join(resultSplit || "\n")}
                      header="Invalid ID's"
                    />
                  )}

                  <ResultJSON
                    data={resultIds}
                    header="JSON"
                    subText={`${inverseCintIDlookup
                        ? "New Cint ID: Old Cint ID"
                        : "Old Cint ID: New Cint ID"
                      }`}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CintIdPage;
