import axios from "axios";
import { BACKEND_URL } from "./useProviderUserRewardConfigs";

export const getInvoiceById = async (panelProvider, id, authToken) => {
  const response = await axios.get(
    `${BACKEND_URL}/portal/provider/${panelProvider}/invoice-data/${id}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  const data = response.data;
  return data;
};

/* Sorts all invoice date IDs into a nested object by spliting the IDs to get the year
and month allowing invoices to be easily accesed using a year and month
for the invoice component
*/
export const convertsInvoiceArrayToObject = (invoiceDateIds) => {
  const invoices = {
    [new Date().getFullYear()]: {},
  };
  const getYearAndMonthRegex = /^(\d{4})[-]?(\d{2})/;

  invoiceDateIds?.forEach((invoiceId) => {
    const yearAndMonth = invoiceId.match(getYearAndMonthRegex);
    if(!yearAndMonth) return;
    const year = yearAndMonth[1];
    const month = 1*yearAndMonth[2]
    if (typeof invoices[year] == "undefined") invoices[year] = {};
    invoices[year][month] = invoiceId;
  });

  return invoices;
};

export const getAllInvoices = async (panelProvider, authToken) => {
  const response = await axios.get(
    `${BACKEND_URL}/portal/provider/${panelProvider}/invoice-data`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  const data = response.data;
  const invoiceData = convertsInvoiceArrayToObject(data);
  return invoiceData;
};
