import React, { useRef, useState, useEffect } from "react";
import CopyButton from "../CopyButton";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const ResultText = ({ text, header }) => {
  const textContainerRef = useRef();
  const [showAllOptions, setShowAllOption] = useState(false);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (!textContainerRef.current) return;
    const overflowing = isOverflown(textContainerRef.current);
    setShowAllOption(overflowing);
    if (!overflowing) setShowAll(false);
  }, [textContainerRef, text]);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      <div>
        <h3 className="cint-id-header">{header}</h3>
        <div
          ref={textContainerRef}
          className={`cint-id-container ${showAll && "show-all-enabled"}`}
        >
          <p>{text}</p>
          <CopyButton text={text} />
        </div>
      </div>
      {showAllOptions && (
        <div className={`show-all-container ${showAll && "show-all-enabled"}`}>
          <button
            className="show-all-button"
            title="Show All"
            onClick={handleShowAll}
          >
            {showAll ? "Collapse" : "Show all"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ResultText;
