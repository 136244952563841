import React from "react";

const CopyButton = ({ text, title = "Copy Text", darkMode = false }) => {
  const handleCopy = () => {
    if (text) navigator.clipboard.writeText(text);
  };

  return (
    <button
      className={`copy ${darkMode && "dark"}`}
      onClick={() => handleCopy(text)}
      title={title}
    >
      <div className="copyIconContainer">
        <div className="rect" style={{ top: "3px", left: "4px" }}></div>
        <div className="rect dark" style={{ top: "6px", left: "8px" }}></div>
        <div className="rect" style={{ top: "8px", left: "10px" }}></div>
      </div>
    </button>
  );
};

export default CopyButton;
