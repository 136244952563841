import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { createBracket } from "../../service/LoiRewards";
import { notify } from "../../utils/notify";

const LoiBracketsNewRow = ({ handleRefetch = () => {}, panelProvider }) => {
  const [edit, setEditMode] = useState(false);
  const [loiLimit, setLoiLimit] = useState(0);
  const [reward, setReward] = useState(0);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setLoiLimit(0);
    setReward(0)
  }, [edit])

  const handleSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();
      await createBracket(
        panelProvider,
        token,
        reward,
        loiLimit
      );

      handleRefetch();
      setEditMode(false);
    } catch (error) {
      console.error(error);
      notify(`Failed to create LOI Bracket: ${error.message}`);
    }
  };
  if (edit) {
    return (
      <tr className="add-loi-row">
        <td data-label="Loi Limit">
          <input
            type="number"
            className="loi_limit"
            title="loi limit"
            onChange={(e) => {
              setLoiLimit(e.target.value);
            }}
            value={loiLimit}
          />
        </td>
        <td data-label="Reward">
          <input
          className="rewards"
          title="reward"
            type="number"
            onChange={(e) => {
              setReward(e.target.value);
            }}
            value={reward}
          />
        </td>
        <td className="tableButtonContainer">
          <button onClick={handleSubmit} className="button saveButton">
            Save
          </button>
          <button
            className="button resetButton"
            onClick={() => {
              setEditMode(false);
            }}
          >
            Cancel
          </button>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td className="table-placeholder"/>
      <td className="table-placeholder"/>
      <td className="tableButtonContainer">
        <button
          className="button editButton"
          onClick={() => {
            setEditMode(true);
          }}
        >
          Add
        </button>
      </td>
    </tr>
  );
};

export default LoiBracketsNewRow;
