import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useCallback } from "react";
import LoiBracketsNewRow from "./LoiBracketsNewRow";
import LoiBracketRow from "./LoiBracketRow";
import ToolTip from "../ToolTip";
import {
  deleteBracket,
  editBracket,
  getBrackets,
} from "../../service/LoiRewards";
import { notify } from "../../utils/notify";
import "./LoiBrackets.scss";
import LoiDeleteModal from "./LoiDeleteModal";

const LoiBrackets = ({ panelProvider }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loiBrackets, setLoiBrackets] = useState([]);
  const [deleteModalVisibilty, setDeleteModalVisibilty] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const brackets = await getBrackets(panelProvider, token);

      setLoiBrackets(brackets);
    } catch (error) {
      console.error(error);
      notify(`Failed to get LOI bracket: ${error.message}`);
    }
  }, [getAccessTokenSilently, panelProvider]);

  useEffect(() => {
    if (panelProvider) {
      fetchData();
    }
  }, [panelProvider, getAccessTokenSilently, fetchData]);

  const changeBracket = async (id, reward, loiLimit) => {
    try {
      const token = await getAccessTokenSilently();
      const data = await editBracket(
        panelProvider,
        token,
        id,
        reward,
        loiLimit
      );
      const newLoi = loiBrackets.map((bracket) =>
        bracket.id === id ? data : bracket
      );
      setLoiBrackets(newLoi);

      return true;
    } catch (error) {
      console.error(error);
      notify(`Failed to edit LOI bracket: ${error.message}`);
      return false;
    }
  };

  const handleDeleteButton = (id) => {
    setSelectedRow(id);
    setDeleteModalVisibilty(true);
  };

  const handleDelete = async () => {
    const id = selectedRow;
    try {
      const token = await getAccessTokenSilently();
      await deleteBracket(panelProvider, token, id);
      const newLoi = loiBrackets.filter((bracket) => +id !== +bracket.id);

      setLoiBrackets(newLoi);
      setDeleteModalVisibilty(false);
    } catch (error) {
      console.error(error);
      notify(`Failed to delete loi bracket ${error.message}`);
    }
  };

  return (
    <div id="loi-brackets">
      <h3 className="reward-title">Loi Brackets</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ overflow: "visible" }}>
              <div className="loi-title-container">
                <p className="reward-title">Loi Limit</p>
                <ToolTip message="The upper limit for the average time it takes a user to do a survey (in minutes)" />
              </div>
            </th>
            <th style={{ overflow: "visible" }}>
              <div className="loi-title-container">
                <p className="reward-title">Reward</p>
                <ToolTip message="The amount to give to a user for completing a survey (in cents or your custom currency)" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="loi-settings">
          {loiBrackets.length === 0 ? (
            <tr>
              <td className="empty-loi-message">
                No current LOI brackets for {panelProvider}
              </td>
            </tr>
          ) : (
            <>
              {loiBrackets
                .sort((a, b) => a.loi_limit - b.loi_limit)
                .map((bracket) => (
                  <LoiBracketRow
                    bracket={bracket}
                    key={bracket.id}
                    handleDelete={handleDeleteButton}
                    changeBracket={changeBracket}
                  />
                ))}
            </>
          )}
          <LoiBracketsNewRow
            panelProvider={panelProvider}
            handleRefetch={fetchData}
          />
        </tbody>
      </table>

      <LoiDeleteModal
        handleDelete={() => handleDelete()}
        handleClose={() => setDeleteModalVisibilty(false)}
        visibility={deleteModalVisibilty}
      />
    </div>
  );
};

export default LoiBrackets;
