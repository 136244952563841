import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { getMonthlyReconcileRate } from "../../service/ProviderStats";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";
import { getDateString, getGraphDateString } from "../../utils/statsUtils";
import useIsMounted from "../../utils/useIsMounted";

const MonthlyReconcileChart = ({ panelProvider }) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const isMounted = useIsMounted();

  const getData = useCallback(async () => {
    const authToken = await getAccessTokenSilently();
    const { data, error } = await getMonthlyReconcileRate(
      panelProvider,
      authToken
    );
    return { data: data?.data || [], error: error };
  }, [panelProvider, getAccessTokenSilently]);

  const handleRetry = () => {
    setError("");
    setLoading(true);
    getData().then(({ data, error }) => {
      if (isMounted()) {
        setLoading(false);
        setData(data);
        setError(error ? "Monthly reconciles could not be loaded" : "");
      }
    });
  };

  useEffect(() => {
    if (panelProvider) {
      setError("");
      setLoading(true);
      getData().then(({ data, error }) => {
        if (isMounted()) {
          setLoading(false);
          setData(data);
          setError(error ? "Monthly reconciles could not be loaded" : "");
        }
      });
    }
  }, [panelProvider, getData, isMounted]);

  const chartOptions = useMemo(() => {
    const orderedData = [...data];
    orderedData.sort((a, b) => (a.key > b.key ? 1 : -1));

    return {
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          let string = "";
          const nextDate = orderedData[params[0].dataIndex + 1]
            ? getDateString(orderedData[params[0].dataIndex + 1].key, 1)
            : "Now";

          string += `${params[0].name} - ${nextDate}<br />`;

          params.forEach((param) => {
            string += `${param.marker} ${param.data}% ${param.seriesName} <br />`;
          });

          return string;
        },
      },
      xAxis: {
        boundaryGap: false,
        type: "category",
        data: orderedData.map(({ key }) => getDateString(key)),
        axisLabel: {
          formatter: (value) => getGraphDateString(value, "m"),
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value) => `${value}%`,
        },
      },
      grid: { containLabel: true, left: 0, right: 10 },
      series: [
        {
          type: "line",
          name: "complete to term rate",
          data: orderedData.map(({ _, value }) =>
            ((value.complete_to_term / value.completes) * 100).toFixed(2)
          ),
          itemStyle: {
            color: "#8D4BF3",
          },
        },
        {
          type: "line",
          name: "term to complete rate",
          data: orderedData.map(({ _, value }) =>
            ((value.term_to_complete / value.completes) * 100).toFixed(2)
          ),
          itemStyle: {
            color: "#5C5BF7",
          },
        },
      ],
    };
  }, [data]);

  const dataLoaded = () => {
    return data && data.length > 0;
  };

  return (
    <div className="graphChart">
      <div className="graphHeaderContainer">
        <p className="chartTitle">Monthly Reconcile rate</p>
        <button
          className="refreshButton"
          title="Reload Active User Chart"
          onClick={handleRetry}
        >
          <RefreshIcon />
        </button>
      </div>

      {loading ? (
        <div className="graphMessageContainer">
          <div className="loaderSpinner" />
        </div>
      ) : error ? (
        <p className="errorMessage error">Error loading data: {error}</p>
      ) : !dataLoaded() ? (
        <div className="graphMessageContainer">
          <p>No Data</p>
        </div>
      ) : (
        <ReactEcharts
          option={chartOptions}
          style={{ height: "100%", width: "100%" }}
          className="chart"
        />
      )}
    </div>
  );
};

export default MonthlyReconcileChart;
