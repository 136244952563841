import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useMemo } from "react";

export const BACKEND_URL =
  process.env.REACT_APP_PROVIDER_API_DOMAIN || "http://localhost:80";

export const useDeleteProviderUserRewardConfigs = (panelProvider) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryKey = `user-reward-config-${panelProvider}`;
  const queryClient = useQueryClient();
  return useMutation(
    async (config) => {
      if (!panelProvider) throw new Error("No panel provider given");
      const authToken = await getAccessTokenSilently();
      await axios({
        url: `${BACKEND_URL}/portal/manage/provider/${panelProvider}/user-reward-configs/${config.id}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return config;
    },
    {
      onSuccess: async (deletedData) => {
        await queryClient.cancelQueries(queryKey);

        queryClient.setQueryData(queryKey, (prevData) => {
          const newData = new Map(prevData);
          const countryToConfigMap = newData?.get(
            deletedData.survey_provider_name
          );
          countryToConfigMap.delete(deletedData.country_code);
          newData.set(deletedData.survey_provider_name, countryToConfigMap);
          return newData;
        });
      },
    }
  );
};

export const useUpdateProviderUserRewardConfigs = (panelProvider) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryKey = `user-reward-config-${panelProvider}`;
  const queryClient = useQueryClient();
  return useMutation(
    async (config) => {
      if (!panelProvider) throw new Error("No panel provider given");
      const authToken = await getAccessTokenSilently();
      const response = await axios.put(
        `${BACKEND_URL}/portal/manage/provider/${panelProvider}/user-reward-configs/${config.id}`,
        config,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response.data;
    },
    {
      onSuccess: async (newData) => {
        await queryClient.cancelQueries(queryKey);
        const previousValue = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (prevData) => {
          const countryToConfigMap =
            prevData?.get(newData.survey_provider_name) || new Map();
          countryToConfigMap.set(newData.country_code, newData);
          return prevData;
        });
        return previousValue;
      },
    }
  );
};

export const useCreateProviderUserRewardConfigs = (panelProvider) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryKey = `user-reward-config-${panelProvider}`;
  const queryClient = useQueryClient();

  return useMutation(
    async (config) => {
      if (!panelProvider) throw new Error("No panel provider given");
      const authToken = await getAccessTokenSilently();
      const response = await axios.post(
        `${BACKEND_URL}/portal/manage/provider/${panelProvider}/user-reward-configs`,
        config,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response.data;
    },
    {
      onSuccess: async (newData) => {
        await queryClient.cancelQueries(queryKey);

        return queryClient.setQueryData(queryKey, (prevData = new Map()) => {
          // create a new map for the data so it knows we made a change and will update
          // useQuery's data
          const newDataMap = new Map(prevData);
          const countryToConfigMap =
            newDataMap?.get(newData.survey_provider_name) || new Map();
          countryToConfigMap.set(newData.country_code, newData);
          newDataMap.set(newData.survey_provider_name, countryToConfigMap);

          return newDataMap;
        });
      },
    }
  );
};

export const useGetProviderUserRewardConfigs = (panelProvider) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryKey = `user-reward-config-${panelProvider}`;
  const queryData = useQuery(
    queryKey,
    async () => {
      if (!panelProvider) throw new Error("No panel provider given");
      if(panelProvider !== 'qmee') return;
      const authToken = await getAccessTokenSilently();
      const url = `${BACKEND_URL}/portal/manage/provider/${panelProvider}/user-reward-configs`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${authToken}` },
      }).catch(() => {
        throw new Error("Unable to fetch data");
      });

      const configs = response.data;
      const providerToCountryConfigMap = new Map();
      configs.forEach((config) => {
        const countryToConfigMap =
          providerToCountryConfigMap.get(config.survey_provider_name) ||
          new Map();

        countryToConfigMap.set(`${config.country_code} ${config.survey_client}`, config);
        providerToCountryConfigMap.set(
          config.survey_provider_name,
          countryToConfigMap
        );
      });

      return providerToCountryConfigMap;
    },
    {
      retry: false,
    }
  );

  return useMemo(
    () => ({
      ...queryData,
    }),
    [queryData]
  );
};
