import React, { useMemo } from 'react'
import {
  CONFIG_FIELD_LABELS
} from "./UserRewardConfigFields";

export const TableDataInput = ({ editMode, dataKey, onChange, value, originalVal }) => {
    
    const handleChange = (e) => {
      onChange(e.target.value, dataKey);  
    }
    const color = useMemo(()=> originalVal !== value ? "#ff6a00" : "", [originalVal, value])
    

    //if input is less than or greater than min max, change to min/max
    const handleBlur = (e)=>{
      if(1*e.target.value > CONFIG_FIELD_LABELS[dataKey].max){
        onChange(CONFIG_FIELD_LABELS[dataKey].max, dataKey)
      }else if(1*e.target.value < CONFIG_FIELD_LABELS[dataKey].min){
        onChange(CONFIG_FIELD_LABELS[dataKey].min, dataKey)
      }
    }

    const inputValue = !value && value !== 0 ? '' : value;
    return (
        <td data-label={CONFIG_FIELD_LABELS[dataKey].name} >
          {editMode? (
          <input
            type="number"
            onChange={handleChange}
            value={inputValue}
            min={CONFIG_FIELD_LABELS[dataKey].min}
            max={CONFIG_FIELD_LABELS[dataKey].max}
            step={CONFIG_FIELD_LABELS[dataKey].step}
            onBlur={handleBlur}
            style={{ color }}
            required={true}
          />

          ):(
            <p>
              {originalVal}
            </p>
          )}

        </td>
    )
}

export default TableDataInput;
