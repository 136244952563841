import React, { useEffect, useState, useCallback } from "react";
import Tile from "./Tile";
import { useAuth0 } from "@auth0/auth0-react";
import { getEarningsPerClick } from "../../service/ProviderStats";
import { usePageVisibility } from "../../service/usePageVisibility";
import useInterval from "../../service/useInterval";
import useIsMounted from "../../utils/useIsMounted";

const tileTitle = {
  h:"Earnings per click (1 hour)",
  d:"Earnings per click (24 hours)",
  w:"Earnings per click (7 days)",
  m:"Earnings per click (30 days)"
}

const EarningsPerClick = ({ panelProvider, interval = "d", timeZone }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(true)
  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const isVisible = usePageVisibility();
  const [paused, setPaused] = useState(false);
  const isMounted = useIsMounted()

  const getEarningsData = useCallback(async () => {
    const authToken = await getAccessTokenSilently();
    const { data, error } = await getEarningsPerClick(interval, panelProvider, authToken, timeZone);

    const epc = `$${(1*(data?.data?.[0]?.value || 0)).toFixed(2) || "0.00"}`;
    return { data: epc, error: error }
  }, [getAccessTokenSilently, panelProvider, interval, timeZone]);

  useInterval(() => {
      let mounted = true;
      if (panelProvider && isVisible) {
        setLoading(true);
        setError("");
        getEarningsData().then(({ data, error }) => {
          if (mounted) {
            setLoading(true);
            setData(data);
            if (error) setError("Failed to get earnings per click");
            setShouldLoad(false)
          }
        });
      } else if (!isVisible) {
        setPaused(true);
      }

      return () => (mounted = false);
    },
    interval === "h" && !paused ? 60000 : null
  );

  useEffect(() => {
    if (isVisible && paused && interval === "h") setPaused(false);
  }, [isVisible, interval, paused, timeZone]);

  useEffect(() => {
    if (panelProvider && interval !== "h") {
      setShouldLoad(true);
      setLoading(true);
      setError("");
      getEarningsData().then(({ data, error }) => {
        if (isMounted()) {
          setLoading(false)
          setData(data);
          if (error) setError("Failed to get earnings per click");
        }
      });
    }
  }, [panelProvider, getEarningsData, interval, timeZone, isMounted]);

  return (
    <Tile
      error={error}
      tileTitle={tileTitle[interval]}
      loading={shouldLoad && loading}
      data={data}
      message="Average revenue for each survey start"
    />
  );
};

export default EarningsPerClick;
