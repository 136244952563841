import React, { useRef, useState, useEffect } from "react";
import CopyButton from "../CopyButton";
import JSONPretty from "react-json-pretty";

function isOverflowing(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const ResultJSON = ({ subText, header, data }) => {
  const textContainerRef = useRef();
  const [showAllOptions, setShowAllOption] = useState(false);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (!textContainerRef.current) return;
    setShowAllOption(isOverflowing(textContainerRef.current));
  }, [textContainerRef, data]);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      <div>
        <h3 className="cint-id-header">{header}</h3>
        <p className="cint-id-subtext">{subText}</p>
      </div>
      <div
        ref={textContainerRef}
        className={`cint-id-json-container ${showAll && "show-all-enabled"}`}
      >
        <JSONPretty id="json-pretty" data={data} />
        <CopyButton text={JSON.stringify(data)} />
      </div>

      {showAllOptions && (
        <div className="show-all-container">
          <button
            className="show-all-button"
            title="Show All"
            onClick={handleShowAll}
          >
            {showAll ? "Collapse" : "Show all"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ResultJSON;
