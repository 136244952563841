import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useCallback, useEffect } from "react";
import { getCurrentRevenue } from "../../service/ProviderStats";
import Tile from "./Tile";
import { usePageVisibility } from "../../service/usePageVisibility.js";
import { addCommasToNumber } from "../../utils/addCommasToNumber";
import useInterval from "../../service/useInterval";
import useIsMounted from "../../utils/useIsMounted";

const labels = {
  d: "Today's Gross Revenue",
  h: "This Hour's Gross Revenue",
  m: "This Month's Gross Revenue",
  w: "This Week's Gross Revenue",
};

const CurrentGrossRevenueTile = ({ panelProvider, interval = "d", timeZone }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState("$0.00");
  const [loading, setLoading] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const isVisible = usePageVisibility();
  const [paused, setPaused] = useState(true);
  const isMounted = useIsMounted();

  const getRevenue = useCallback(async () => {
    setError("");

    const authToken = await getAccessTokenSilently();
    const { data, error } = await getCurrentRevenue(
      interval,
      panelProvider,
      authToken,
      timeZone
    );

    const revenue = (1* (data?.data?.[0]?.value || 0)/100).toFixed(2);
    return { data: `$${addCommasToNumber(revenue)}`, error: error };
  }, [panelProvider, getAccessTokenSilently, interval, timeZone]);

  useInterval(
    () => {
      let mounted = true;
      if (panelProvider && isVisible) {
        setLoading(true);
        getRevenue().then(({ data, error })=>{
          if (mounted) {
            setData(data);
            setLoading(false);
            setShouldLoad(false);
            setError(error ? "Revenue data could not be loaded" : ""); 
          }
        });
      } else if (!isVisible) setPaused(true);

      return () => {
        mounted = false;
      };
    },
    interval === "h" && !paused ? 60000 : null
  );

  useEffect(() => {
    if (panelProvider && interval !== "h") {
      setShouldLoad(true);
      setLoading(true);
      getRevenue().then(({ data, error }) => {
        if (isMounted()) {
          setData(data);
          setLoading(false);
          setError(error ? "Revenue data could not be loaded" : "");
        }
      })
    }
  }, [panelProvider, interval, getRevenue, timeZone, isMounted]);

  useEffect(() => {
    if (isVisible && paused && interval === "h") setPaused(false);
  }, [isVisible, interval, paused, timeZone]);

  return (
    <Tile
      error={error}
      tileTitle={labels[interval]}
      loading={shouldLoad && loading}
      data={data}
    />
  );
};

export default CurrentGrossRevenueTile;
