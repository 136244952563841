export const getDateString = (date, offset) => {
  date = date?.replace?.(/ /g, "T");
  if(/\+\d\d$/.test(date)) date += ':00';

  const d = new Date(date);
  if (offset) d.setDate(d.getDate() - offset);
  const dateString = d.toDateString();
  return dateString.substr(dateString.indexOf(" ") + 1);
};

export const getTimeString = (date) => {
  const d = new Date(date);
  return d.toLocaleTimeString();
};

const addZero = (val) => ((val + "").length === 1 ? "0" + val : val);

export const getGraphDateString = (graphDate, type) => {
  var date;
  switch (type) {
    case "h":
      return graphDate;
    case "m":
      date = new Date(graphDate);
      if (date.getDate() > 15) date.setMonth(date.getMonth() + 1);
      return `${addZero(date.getMonth() + 1)}/${(date.getFullYear() + "").slice(
        -2
      )}`;
    default:
      date = new Date(graphDate);
      return `${addZero(date.getDate())}/${addZero(date.getMonth() + 1)}`;
  }
};
