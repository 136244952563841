import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { updateProviderSettings } from "../../service/ProviderSettings";
import { compareObjects } from "../../utils/compareObjects";
import { notify } from "../../utils/notify";
import "./ProviderForm.scss";
import CurrencyEquation from "./CurrencyEquation";
import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";
import LoiBrackets from "../LoiBrackets/LoiBrackets";
import { getProviderSettings } from "../../service/ProviderSettings";

const ProviderForm = ({ panelProvider }) => {
  const [inputData, setInputData] = useState({});
  const [savedData, setSavedData] = useState({});
  const [providerFormError, setProviderFormError] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [error, setError] = useState("");
  const [customCurrencyCheck, setCustomCurrencyCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [equationError, setEquationError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const rangeRef = useRef(null);

  const firstRun = useRef(true);

  const getProviderFormData = useCallback(async () => {
    setProviderFormError("");

    const authToken = await getAccessTokenSilently();
    const { providerSettings, error } = await getProviderSettings(
      panelProvider,
      authToken
    );

    setSavedData({ ...providerSettings });
    if (error) setProviderFormError(error.message);
  }, [getAccessTokenSilently, panelProvider]);

  useEffect(() => {
    if (panelProvider && firstRun.current) {
      firstRun.current = false;
      getProviderFormData(panelProvider);
    }
  }, [panelProvider, getProviderFormData]);

  useEffect(() => {
    if (error) notify(error);
  }, [error]);

  useEffect(() => {
    const match = compareObjects(savedData, inputData);
    setShowSave(!match);
  }, [inputData, savedData, showSave, customCurrencyCheck]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const match = compareObjects(savedData, inputData);
    if (match) {
      setError("Saved data is the same");
      return;
    }
    handleUpdateProviderSettings();
  };

  const handleChange = (e) => {
    const newInput = {
      ...inputData,
      [e.target.name]: e.target.value,
    };

    if (e.target.name === "user_revenue_share" && rangeRef.current) {
      const min = e.target.min;
      const max = e.target.max;
      const val = e.target.value;
      rangeRef.current.style.backgroundSize =
        ((val - min) * 100) / (max - min) + "% 100%";

      if (e.target.value > 100) {
        newInput[e.target.name] = 100;
      } else if (e.target.value < 0) {
        newInput[e.target.name] = 0;
      }
    }
    setInputData(newInput);
  };

  const handleUpdateProviderSettings = async () => {
    if (loading) return;
    setLoading(true);
    setError("");
    const authToken = await getAccessTokenSilently();

    const { error } = await updateProviderSettings(
      inputData,
      panelProvider,
      authToken
    );
    if (!error) {
      notify("Changed data successfully");
      setShowSave(false);
      setSavedData(inputData);
    } else {
      setError(error.message);
      notify(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    setInputData(savedData);
    if (rangeRef.current && savedData) {
      const min = rangeRef.current.min;
      const max = rangeRef.current.max;
      rangeRef.current.style.backgroundSize =
        ((savedData.user_revenue_share - min) * 100) / (max - min) + "% 100%";
    }
    setCustomCurrencyCheck(savedData?.custom_currency_format !== null);
  }, [savedData]);

  useEffect(()=>{
    if (rangeRef.current && !isNaN(inputData.user_revenue_share)) {
      const min = rangeRef.current.min;
      const max = rangeRef.current.max;
      const val = inputData.user_revenue_share || 0;
      rangeRef.current.style.backgroundSize =
        ((val - min) * 100) / (max - min) + "% 100%";

      rangeRef.current.value =  inputData.user_revenue_share
    }
  }, [inputData])

  const handleCancel = () => {
    setInputData({
      ...savedData,
    });
    if (rangeRef.current) {
      const min = rangeRef.current.min;
      const max = rangeRef.current.max;
      rangeRef.current.style.backgroundSize =
        ((savedData.user_revenue_share - min) * 100) / (max - min) + "% 100%";
    }
    setCustomCurrencyCheck(savedData?.custom_currency_format !== null);
    setShowSave(false);
    setError("");
  };

  const handleNewUserCheckBox = (e) => {
    setInputData({
      ...inputData,
      use_new_user_surveys: e.target.checked ? "true" : "false",
    });
  };

  const handleCheckboxChange = (e) => {
    setCustomCurrencyCheck(e.target.checked);

    if (!e.target.checked) {
      setInputData({
        ...inputData,
        custom_currency_format: null,
        currency_conversion_rate: null,
      });
    } else {
      setInputData({
        ...inputData,
        custom_currency_format: savedData.custom_currency_format || "",
        currency_conversion_rate: savedData.currency_conversion_rate,
      });
    }
  };

  const handleFormatValidationChange = (bool) => {
    setEquationError(bool);
  };

  if (providerFormError) {
    return (
      <div className="errorContainer">
        <p className="errorProviderMessage">
          Failed to load: {providerFormError}
        </p>
        <button className="button is-light" onClick={getProviderFormData}>
          Refetch
        </button>
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="providerSettingsForm">
        {!error && (
          <>
            <div className="providerSettingsContainer">
              <div className="field">
                <label className="label" htmlFor="user_revenue_share_input">
                  Default User Rewards Calculator
                </label>
                <div className="control">
                  <select
                    id="reward_calculator"
                    value={
                      !inputData.reward_calculator
                        ? ""
                        : inputData.reward_calculator
                    }
                    name="reward_calculator"
                    className="select"
                    title="Percentage of revenue share a user gets"
                    onChange={handleChange}
                    disabled={!Object.keys(savedData).length}
                  >
                    <option value="rev_share">User Revenue share</option>
                    <option value="loi_bracket">Loi Brackets</option>
                  </select>
                </div>
              </div>
              {inputData.reward_calculator !== "loi_bracket" && (
                <div className="field">
                  <label className="label" htmlFor="user_revenue_share_input">
                    User Revenue share
                  </label>
                  <div
                    className="control"
                    style={{ textAlign: "center", margin: "30px 0" }}
                  >
                    <div className="revShareInputContainer">
                      <input
                        type="number"
                        min="0"
                        max="100"
                        step="1"
                        value={
                          !inputData.user_revenue_share
                            ? ""
                            : inputData.user_revenue_share
                        }
                        name="user_revenue_share"
                        className="input user_rev_share"
                        title="Percentage of revenue share a user gets"
                        onChange={handleChange}
                        disabled={!Object.keys(savedData).length}
                      />
                      <span>%</span>
                    </div>

                    <input
                      id="user_revenue_share_input"
                      type="range"
                      ref={rangeRef}
                      min="0"
                      max="100"
                      step="1"
                      value={
                        !inputData.user_revenue_share
                          ? 0
                          : inputData.user_revenue_share
                      }
                      name="user_revenue_share"
                      className="slider is-fullwidth"
                      title="Percentage of revenue share a user gets"
                      onChange={handleChange}
                      style={{
                        margin: "20px auto 0",
                      }}
                      disabled={!Object.keys(savedData).length}
                    />
                  </div>
                </div>
              )}

              {inputData.reward_calculator === "loi_bracket" && (
                <div className="field">
                  <label
                    className="label checkbox"
                    htmlFor="use_new_user_surveys"
                  >
                    <input
                      type="checkbox"
                      id="use_new_user_surveys"
                      name="use_new_user_surveys"
                      className="checkbox"
                      checked={inputData.use_new_user_surveys === "true"}
                      onChange={handleNewUserCheckBox}
                      disabled={!Object.keys(savedData).length}
                    />
                    Show new users surveys
                  </label>
                  <p>
                    Show new users a list of surveys instead of just a signup
                    survey.
                  </p>
                </div>
              )}

              <div className="field">
                <label
                  className="label checkbox"
                  htmlFor="custom_currency_check"
                >
                  <input
                    type="checkbox"
                    id="custom_currency_check"
                    name="custom_currency_check"
                    className="checkbox"
                    checked={customCurrencyCheck}
                    onChange={handleCheckboxChange}
                    disabled={!Object.keys(savedData).length}
                  />
                  Use a Custom Currency
                </label>

                <p>
                  Qurated allows the use of completely custom currencies. Your
                  business may have ‘Tokens’ or ‘Points’
                </p>
              </div>
              <div
                className={customCurrencyCheck ? "" : "disabledCurrencyCheck"}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div className="field">
                    <label
                      className="label"
                      htmlFor="currency_converion_format_input"
                    >
                      Custom currency format
                    </label>
                    <div className="control">
                      <input
                        type="text"
                        id="currency_converion_format_input"
                        name="custom_currency_format"
                        className={`input ${equationError ? "is-danger" : ""}`}
                        title="currency format using the sprintf format"
                        value={
                          !inputData.custom_currency_format
                            ? ""
                            : inputData.custom_currency_format
                        }
                        disabled={
                          !customCurrencyCheck || !Object.keys(savedData).length
                        }
                        onChange={handleChange}
                      />

                      {equationError && (
                        <p className="errMessage">Currency format is invalid</p>
                      )}
                    </div>
                  </div>
                  {inputData.reward_calculator !== "loi_bracket" && (
                    <>
                      <label
                        className="label"
                        htmlFor="currency_converion_rate_input"
                      >
                        Conversion rate
                      </label>
                      <div className="container">
                        <input
                          type="number"
                          id="currency_converion_rate_input"
                          name="currency_conversion_rate"
                          step="0.01"
                          className="input"
                          title="Positive float"
                          disabled={
                            !customCurrencyCheck ||
                            !Object.keys(savedData).length
                          }
                          value={
                            !inputData.currency_conversion_rate &&
                            inputData.currency_conversion_rate !== 0
                              ? ""
                              : inputData.currency_conversion_rate
                          }
                          onChange={handleChange}
                          min="0"
                          required
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {error && (
          <div className="providerSettingsDiv">
            <p className="errorMessage">Error received: {error}</p>
          </div>
        )}

        <div className="providerSettingsContainer">
          {inputData.reward_calculator !== "loi_bracket" && (
            <div className="field">
              <label className="label checkbox" htmlFor="use_new_user_surveys">
                <input
                  type="checkbox"
                  id="use_new_user_surveys"
                  name="use_new_user_surveys"
                  className="checkbox"
                  checked={inputData.use_new_user_surveys === "true"}
                  onChange={handleNewUserCheckBox}
                  disabled={!Object.keys(savedData).length}
                />
                Show new users surveys
              </label>
              <p>
                Show new users a list of surveys instead of just a signup
                survey.
              </p>
            </div>
          )}

          <div className="field">
            <label className="label">Example Currency Calcuations</label>
            <div className="control">
              <CurrencyEquation
                rewardCalculator={inputData.reward_calculator}
                userRevShare={inputData.user_revenue_share}
                conversionRate={inputData.currency_conversion_rate}
                currencyFormat={inputData.custom_currency_format}
                handleValidationChange={handleFormatValidationChange}
                formatInvalid={equationError}
              />
            </div>
            <div
              className="endpointNotes"
              style={{ marginTop: "20px", width: "100%" }}
            >
              <div className="tableContainer">
                <p>Uses sprintf for currency formatting</p>
                <table style={{ width: "100%", marginTop: "20px" }}>
                  <thead>
                    <tr>
                      <th>Input</th>
                      <th>Output</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>%d Coins</td>
                      <td>13 Coins</td>
                    </tr>
                    <tr>
                      <td>%.02f Coins</td>
                      <td>13.50 Coins</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {inputData.reward_calculator !== "loi_bracket" && (
              <div className="customCurrencyRoundingInfo">
                <InfoIcon className="currencyRoundingIcon" />
                <p>
                  Custom currency values are rounded down to the specified
                  nearest decimal place. See the currency formatting information
                  above for more details on specifying accuracy.
                </p>
              </div>
            )}
          </div>

          <div className="buttonsContainer">
            <button
              className={`button is-info submit`}
              type="submit"
              disabled={
                !(showSave && !!Object.keys(savedData).length) || equationError
              }
            >
              Save Changes
            </button>
            <button
              className={`button is-light`}
              onClick={handleCancel}
              type="button"
              disabled={!(showSave && !!Object.keys(savedData).length)}
            >
              Revert
            </button>
          </div>
        </div>
      </form>
      {inputData.reward_calculator === "loi_bracket" && (
        <LoiBrackets data={savedData} panelProvider={panelProvider} />
      )}
    </div>
  );
};

export default ProviderForm;
