import React from "react";

const LoiDeleteModal = ({ visibility, handleDelete, handleClose }) => {
  return (
    <div className={visibility ? "modal is-active" : "modal"}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Delete LOI Bracket</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => handleClose()}
          />
        </header>
        <section className="modal-card-body">
          <p>Are you sure you want to delete this LOI bracket?</p>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-danger" onClick={() => handleDelete()}>
            Delete
          </button>
          <button className="button" onClick={() => handleClose()}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default LoiDeleteModal;
