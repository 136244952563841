import React from "react";
import "bulma/css/bulma.css";
import "./UserRewards.scss";
import ProviderForm from "../components/ProviderSettings/ProviderForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QmeeUserRewards from "../components/UserRewards/QmeeUserRewards";

const UserRewards = (props) => {

  const panelProvider = props.match?.params?.panel_provider;

  if (panelProvider === "qmee") {
    return <QmeeUserRewards panelProvider={panelProvider} />;
  }

  return (
    <section
      className="section"
      style={{ padding: "0 1.5rem", marginTop: "0" }}
    >
      <ToastContainer />
      <div className="container">
        <div
          className="userRewardsHeader"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <h1 className="title">Offerwall Settings</h1>
        </div>
        <ProviderForm panelProvider={panelProvider} />
      </div>
    </section>
  );
};

export default UserRewards;
