import React from "react";
import "./DeleteModal.scss";

const DeleteModal = ({
  onClose,
  onDelete,
  header,
  message
}) => {
  const handleDelete = () => onDelete();

  const handleClose = () => onClose();

  return (
    <div className="deleteModal">
      <div className="modalBackground" onClick={handleClose} />
      <div className="tile box is-parent is-vertical notification deleteModalContainer">
        <h3>{header}</h3>
        <p>{message}</p>
        <div className="buttons">
          <button
            className="button is-info delete-confirm"
            type="submit"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            className="button is-info delete-cancel"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteModal.defaultProps = {
  header: "Confirm Delete?",
  message: "Are you sure you want to delete this record?",
};

export default DeleteModal;
