import React, { useContext } from "react";
import UserContext from "../../shared/user-context";
import "./PanelistDescription.scss"; 

const PanelistDescription = ({ userDescription }) => {
    const currentUser = useContext(UserContext);

    return (
        <div className="panelistDescripion">
            <h1>{userDescription.panel_provider_user_guid}</h1>
            {currentUser?.qmee_admin && userDescription.qmee_user_guid &&
                <p>Qmee User Guid: {userDescription.qmee_user_guid}</p>
            }
            <p>Country: {userDescription.country_code}</p>
            {userDescription.quality &&
                <>
                    <h2>User quality</h2>
                    <ul>
                        {userDescription.quality.paused ? (
                                <li>{userDescription.quality.paused_explanation}</li>
                            ) : <li>Not currently paused by Qmee</li>
                        }
                        {userDescription.quality.provider_paused ? (
                            <li>{userDescription.quality.provider_paused_explanation}</li>
                            ) : <li>Not currently paused by any survey providers</li>
                        }
                    </ul>
                    {userDescription.quality.quality_reasons && 
                        userDescription.quality.quality_reasons.length > 0 &&
                        <>
                            <h3>Some of the quality issues we have seen for this user:</h3>
                            <ul>
                                {userDescription.quality.quality_reasons.map((reason) =>
                                    <li>{reason}</li>
                                )}
                            </ul>
                        </>
                    }
                </>
            }
        </div>
    )
};

export default PanelistDescription;
