import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { postTestEndpoint } from "../../service/EndPoints";

const TestEndpoints = ({ data = {}, panelProvider }) => {
  const [modalActive, setModalActive] = useState(false);
  const [inputData, setInputData] = useState({});
  const [endpointType, setEndpointType] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  const handleModal = () => {
    setModalActive((prev) => !prev);
  };

  const handleChange = (e) => {
    setMessage("");
    setError("");
    setInputData({ ...inputData, [e.target.name]: e.target.value || null });
  };

  const handleEndpointType = (e) => {
    setMessage("");
    setError("");
    setEndpointType(e.target.value);
  };

  const handlePostEndpoint = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setMessage("");
      setError("");
      const authToken = await getAccessTokenSilently();
      const response = await postTestEndpoint(
        endpointType,
        panelProvider,
        inputData,
        authToken
      );
      const { data: { postback_status_code: status } = {} } = response;

      setMessage(
        status >= 200 && status <= 299
          ? `✅ Postback to '${inputData.url}' was successful. Response code: ${status}`
          : `🚫 Postback to '${inputData.url}' failed. Response code: ${status}`
      );
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data[endpointType]) {
      setInputData((prev) => ({ ...prev, url: data[endpointType] }));
    }
  }, [endpointType, data]);

  return (
    <div style={{ margin: "20px 0" }}>
      <button className="button is-info testEndpoint" onClick={handleModal}>
        Test Endpoints
      </button>
      <form
        onSubmit={handlePostEndpoint}
        className={`modal ${modalActive ? "is-active" : ""}`}
      >
        <div className="modal-background" onClick={handleModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Test Endpoints</p>
            <button
              className="delete"
              aria-label="close"
              onClick={handleModal}
            />
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label htmlFor="endpoint_type" className="label checkbox">
                Endpoint Type
              </label>
              <div className="select">
                <select
                  id="endpoint_type"
                  value={endpointType}
                  className="select changeProvider"
                  onChange={handleEndpointType}
                  required
                >
                  <option value="" disabled>
                    Select Endpoint
                  </option>
                  <option value="postback">Postback</option>
                  <option value="notification">Notifications</option>
                  <option value="user_banned">User Banned</option>
                </select>
              </div>
            </div>
            {endpointType && (
              <>
                <div className="field">
                  <div className="labelContainer">
                    <label className="label" htmlFor="test-url">
                      URL
                    </label>
                    <span className="required"> (required)</span>
                  </div>

                  <div className="control">
                    <input
                      type="url"
                      id="test-url"
                      name="url"
                      className="input"
                      placeholder="Endpoint URL"
                      value={inputData.url || ""}
                      onChange={handleChange}
                      required={true}
                    />
                  </div>
                </div>
                {endpointType === "postback" && (
                  <div className="field">
                    <label htmlFor="status" className="label checkbox">
                      Status
                    </label>
                    <div className="select">
                      <select
                        name="status"
                        id="status"
                        value={inputData.status || ""}
                        onChange={handleChange}
                      >
                        <option value="complete">Complete</option>
                        <option value="termionate">Terminate</option>
                        <option value="quota_full">Quota full</option>
                        <option value="screenout">Screenout</option>
                        <option value="not_available">Not available</option>
                        <option value="used_link">Used link</option>
                        <option value="expired_link">Expired link</option>
                      </select>
                    </div>
                  </div>
                )}

                {endpointType === "notification" ? (
                  <div className="field">
                    <div className="labelContainer">
                      <label
                        className="label checkbox"
                        htmlFor="user_guid_list"
                      >
                        List of User Guids
                      </label>
                      <span className="required"> (optional)</span>
                    </div>

                    <textarea
                      style={{ width: "100%", resize: "none" }}
                      value={inputData.user_guid_list || ""}
                      placeholder="Commma separated list of user guids"
                      className="textarea"
                      onChange={handleChange}
                      rows="4"
                      id="user_guid_list"
                      name="user_guid_list"
                      disabled={!Object.keys(data).length}
                    />
                  </div>
                ) : (
                  <div className="field">
                    <div className="labelContainer">
                      <label className="label checkbox" htmlFor="user_guid">
                        User Guid
                      </label>
                      <span className="required"> (optional)</span>
                    </div>
                    <input
                      value={inputData.user_guid || ""}
                      onChange={handleChange}
                      type="text"
                      id="user_guid"
                      name="user_guid"
                      placeholder="Enter a user guid"
                      className="input"
                      disabled={!Object.keys(data).length}
                    />
                  </div>
                )}
              </>
            )}

            {message && <p>{message}</p>}
            {error && <p className="errorMessage">Failed to send: {error}</p>}
          </section>
          <footer className="modal-card-foot">
            <button
              className={`button is-success ${loading ? "is-loading" : ""}`}
              disabled={loading}
              type="submit"
            >
              Send
            </button>
            <button className="button" onClick={handleModal}>
              Cancel
            </button>
          </footer>
        </div>
      </form>
    </div>
  );
};

export default TestEndpoints;
