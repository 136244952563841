import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import ApiKey from "./ApiKey";
import "./Settings.scss";
import { getProviderSettings } from "../../service/ProviderSettings";
import { notify } from "../../utils/notify";


const Settings = ({ panelProvider }) => {
  const [apiKey, setApiKey] = useState("");
  const [panelName, setPanelName] = useState("");
  const [apiKeyError, setApiKeyError] = useState(false);
  const [apiKeyLoading, setApiKeyLoading] = useState(false);
  const firstRun = useRef(true);
  const { getAccessTokenSilently } = useAuth0();

  const getData = useCallback(async () => {
    setApiKeyError("");
    setApiKeyLoading(true);

    const authToken = await getAccessTokenSilently();
    const { apiKey, error, name } = await getProviderSettings(
      panelProvider,
      authToken
    );

    setApiKey(apiKey);
    setPanelName(name);
    if (error) {
      setApiKeyError(error.message);
    }
    setApiKeyLoading(false);
  }, [getAccessTokenSilently, panelProvider]);

  useEffect(() => {
    if (panelProvider && firstRun.current) {
      firstRun.current = false;
      getData(panelProvider);
    }
  }, [panelProvider, getData]);

  useEffect(() => {
    if (apiKeyError) notify(apiKeyError);
  }, [apiKeyError]);

  if (apiKeyError) {
    <div className="errorContainer">
      <p className="errorMessage">Failed to load: {apiKeyError}</p>
      <button className="button is-light" onClick={getData}>
        Refetch
      </button>
    </div>;
  }

  return (
    <div className="settingsContainer">
      <div className="panelProviderText">
        <h3 className="subtitle is-5">Provider Name</h3>
        <h4>{panelName}</h4>
      </div>

      <ApiKey apiKey={apiKey} loading={apiKeyLoading} />
    </div>
  );
};

export default Settings;
