import React, { useEffect, useState, useCallback } from "react";
import Tile from "./Tile";
import { useAuth0 } from "@auth0/auth0-react";
import { getStartsPerUser } from "../../service/ProviderStats";
import useIsMounted from "../../utils/useIsMounted";

const StartsPerUserTile = ({ panelProvider }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const isMounted = useIsMounted();

  const getAverageUserStart = useCallback(async () => {
    const authToken = await getAccessTokenSilently();
    const { data, error } = await getStartsPerUser(panelProvider, authToken);
    const userStarts = (1*(data?.data?.[0]?.value || 0))?.toFixed(2) || "0.00";
    return { data: userStarts, error };
  }, [getAccessTokenSilently, panelProvider]);

  useEffect(() => {
    if (panelProvider && isMounted()) {
      setLoading(true);
      setError("");
      getAverageUserStart().then(({ data, error }) => {
        if (isMounted()) {
          setData(data);
          if (error) setError("Failed to get starts per user");
          setLoading(false);
        }
      });
    }
  }, [getAverageUserStart, panelProvider, isMounted]);

  return (
    <Tile
      error={error}
      tileTitle="Starts Per Active User (14 days)"
      loading={loading}
      data={data}
      message="Average number of starts per active user"
    />
  );
};

export default StartsPerUserTile;
