import React, { useState } from "react";
import InvoicePDF from "./InvoicePDF";
import { useAuth0 } from "@auth0/auth0-react";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { ReactComponent as DownloadIcon } from "../../assets/download-icon.svg";
import { getInvoiceById } from "../../service/Invoice";
import { toast } from "react-toastify";

const showErrorMessage = (message) => {
  toast.error(message);
};

const convertToPDFName = (name)=>{
  return name.replace(/(\.\w+)?$/, '.pdf')
}

const InvoiceRow = ({ month, invoiceId, panelProvider, invoiceDate }) => {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const generatePDF = async () => {
    setLoading(true);
    try {
      const authToken = await getAccessTokenSilently();
      const result = await getInvoiceById(panelProvider, invoiceId, authToken);
      const blob = await pdf(
        <InvoicePDF invoice={result} invoiceDate={invoiceDate} />
      ).toBlob();
      saveAs(blob, convertToPDFName(result.file_name));
    } catch (err) {
      showErrorMessage("Failed to get invoice: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <tr
      id={`${month.toLowerCase()}-row`}
      key={month}
      className={`invoiceRow ${!invoiceId ? "disabled" : ""}`}
      style={{ cursor: "pointer", verticalAlign: "middle" }}
    >
      <td style={{ verticalAlign: "middle" }}>{month}</td>

      <td className="settingsButtons">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <button
            className="downloadButton"
            onClick={generatePDF}
            title="Download Invoice"
          >
            <DownloadIcon className="icon" />
          </button>
        )}
      </td>
    </tr>
  );
};

export default InvoiceRow;
