import React, { useEffect, useState, useCallback } from "react";
import Tile from "./Tile";
import { useAuth0 } from "@auth0/auth0-react";
import { getEngagementRate } from "../../service/ProviderStats";
import useIsMounted from "../../utils/useIsMounted";

const tileTitle = {
  h:"Engagement Rate (24 hour)",
  d:"Engagement Rate (24 hours)",
  w:"Engagement Rate (7 days)",
  m:"Engagement Rate (30 days)"
}

const EngagementRateTile = ({ panelProvider, interval = "d", timeZone }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(true);
  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const isMounted = useIsMounted()

  const getEngagementData = useCallback(async () => {
    setError("");
    const authToken = await getAccessTokenSilently();
    const { data, error } = await getEngagementRate(panelProvider, authToken, interval, timeZone);
    return { data, error }
  }, [getAccessTokenSilently, panelProvider, interval, timeZone]);

  useEffect(() => {
    if (panelProvider) {
      setLoading(true);
      setShouldLoad(true);
      getEngagementData().then(({ data, error }) => {
        if (isMounted()) {
          setLoading(false);
          setData(`${(100*data?.data?.[0]?.value || 0)?.toFixed(2) || "0.00"}%`);
          setError(error ? "Failed to get engagement rate": "");
        }
      });
    }
  }, [getEngagementData, panelProvider, interval, isMounted]);

  return (
    <Tile
      error={error}
      tileTitle={tileTitle[interval]}
      loading={shouldLoad && loading}
      data={data}
      message="Unique user starts / Unique user offerwall refreshes"
    />
  );
};

export default EngagementRateTile;
