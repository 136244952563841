import React, { useEffect, useState, useCallback } from "react";
import "./App.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { Switch, Route, useHistory } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import UserContext from "./shared/user-context";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login.jsx";
import Overview from "./pages/Overview.jsx";
import Users from "./pages/Users.jsx";
import { getCurrentUser } from "./service/Users";
import UserRewards from "./pages/UserRewards";
import LoginSuccess from "./components/LoginSuccess";
import SettingsPage from "./pages/SettingsPage";
import InvoicePage from "./pages/InvoicePage";
import Sidebar from "./components/Sidebar";
import Nav from "./components/Nav";
import { Redirect } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Loading from "./components/Loading";
import PanelistsPage from "./pages/Panelist";
import PanelContextProvider from "./shared/panel-context";
import { Router } from "react-router-dom/cjs/react-router-dom.min";
import Demographics from "./pages/Demographics";
import ProviderAdminRoute from "./components/ProviderAdminRoute";
import CintIdPage from "./pages/CintIdPage";

const App = () => {
  const { getAccessTokenSilently, user, isLoading } = useAuth0();
  const [loadingCurrentUser, setLoadingCurrentUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(false);
  const history = useHistory();
  const handleRetry = () => {
    setError(false);
    getUser();
  };

  const getUser = useCallback(async () => {
    setLoadingCurrentUser(true);
    const accessToken = await getAccessTokenSilently();
    getCurrentUser(accessToken)
      .then((user) => {
        if (user) setCurrentUser(user);
      })
      .catch((error) => {
        console.log(error.message);
        setError(true);
      })
      .finally(() => {
        setLoadingCurrentUser(false);
      });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (user && currentUser == null && !loadingCurrentUser && !error) {
      getUser();
    }
  }, [user, getUser, loadingCurrentUser, currentUser, error]);

  return (
    <QueryClientProvider client={new QueryClient()}>
      <UserContext.Provider value={currentUser}>
        <PanelContextProvider>
          <React.Fragment>
            <Router history={history}>
              <div className="flex">
                <Sidebar loading={!currentUser?.panel_provider} />

                <div className="appContainer">
                  <Nav />

                  {(isLoading || user) && !currentUser?.panel_provider ? (
                    <div>
                      {!error ? (
                        <Loading />
                      ) : (
                        <div className="failedAuthContainer">
                          <div>
                            <p className="errorHeader">
                              Failed to Authenticate user
                            </p>

                            <div className="noDataError">
                              <button className="button" onClick={handleRetry}>
                                Retry
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/login" />
                      </Route>

                      <Route path="/login" component={Login} />
                      <PrivateRoute
                        path="/login-success"
                        component={LoginSuccess}
                      />
                      <PrivateRoute path="/overview" component={LoginSuccess} />
                      <PrivateRoute
                        path="/:panel_provider/overview/:interval"
                        component={Overview}
                      />
                      <PrivateRoute
                        exact
                        path="/:panel_provider/overview"
                        component={Overview}
                      />
                      <PrivateRoute
                        path="/:panel_provider/demographics"
                        component={Demographics}
                      />
                      <ProviderAdminRoute
                        path="/:panel_provider/users"
                        component={Users}
                        user={currentUser} />
                      <ProviderAdminRoute
                        path="/:panel_provider/reward-settings"
                        component={UserRewards}
                        user={currentUser} 
                      />
                      <ProviderAdminRoute
                        path="/:panel_provider/settings"
                        component={SettingsPage}
                        user={currentUser} 
                      />
                      <PrivateRoute
                        path="/:panel_provider/cint-ids"
                        component={CintIdPage}
                        panelProvider={"lifepoints"}
                      />
                      <PrivateRoute
                        path="/:panel_provider/panel-users"
                        component={PanelistsPage}
                      />
                      <ProviderAdminRoute
                        path="/:panel_provider/invoice"
                        component={InvoicePage}
                        user={currentUser}
                      />
                      <PrivateRoute component={NotFound} />
                    </Switch>
                  )}
                </div>
              </div>
            </Router>
          </React.Fragment>
        </PanelContextProvider>
      </UserContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
