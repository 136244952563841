import React from "react";
import ToolTip from "../ToolTip";
import "./EndpointQuestion.scss";

const EndpointQuestion = ({
  labelName,
  handleChange,
  value,
  name,
  inputId,
  required,
  children,
  disabled,
  message,
}) => {
  return (
    <div className="field endpoint-question">
      <div className="labelContainer">
        <label className="label" htmlFor={inputId}>
          {labelName} {message && <ToolTip message={message}></ToolTip>}
        </label>
        {required && <span className="required"> (required)</span>}
      </div>

      {children}
      <div className="control">
        <input
          type="text"
          id={inputId}
          name={name}
          pattern="[\w\-._~:\/?#!$&'()*+,;%=]+"
          className="input"
          value={value}
          onChange={handleChange}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

EndpointQuestion.defaultProps = {
  labelName: "",
  handleChange: () => {},
  value: "",
};

export default EndpointQuestion;
