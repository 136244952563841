import React from "react";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="pageNotFound">
      <div>
        <h1 className="title">404</h1>
        <p>Page not found</p>
      </div>
    </div>
  );
};

export default NotFound;
