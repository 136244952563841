import React, { useEffect, useState, useContext } from "react";
import { PanelContext } from "../shared/panel-context";
import axios from "axios";
import { BACKEND_URL } from "../service/useProviderUserRewardConfigs";
import { useAuth0 } from "@auth0/auth0-react";
import "./ProviderDropdown.scss";

const ProviderDropdown = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [allProviders, setAllProviders] = useState([]);
  const panelContext = useContext(PanelContext);

  const handleProviderChange = (e) => panelContext.updatePanel(e.target.value);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const token = await getAccessTokenSilently();
      try {
        const response = await axios.get(
          `${BACKEND_URL}/portal/valid-providers`,
          {
            headers: { Authorization: `Bearer ${token}` }
        });
        const data = response.data;
        if (mounted) setAllProviders(data);
      } catch (error) {
        console.error(error);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [getAccessTokenSilently]);

  if (allProviders.length === 0) return <p>{panelContext?.panel}</p>;
  return (
    <select
      className="changeProvider"
      onChange={handleProviderChange}
      value={panelContext?.panel}
    >
      {allProviders.map((provider) => (
        <option value={provider} key={provider}>
          {provider}
        </option>
      ))}
    </select>
  );
};

export default ProviderDropdown;
