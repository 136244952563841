export const CONFIG_FIELD_KEYS = [
  "min_user_income",
  "max_user_income",
  "min_random_user_income",
  "max_random_user_income",
  "target_profit_margin",
  "target_profit_margin_adjustment",
  "min_profit",
  "length_in_minutes_variance",
  "median_length_in_minutes",
  "score_variance",
  "mean_score",
  "high_cpi_bonus_threshold",
  "high_cpi_bonus_percentage",
  "post_high_cpi_bonus_max_reward",
];
export const CONFIG_FIELD_LABELS = {
  min_user_income: { name: "Minimum User Income", min: 5, max: 10, step: 1 },
  max_user_income: { name: "Maximum User Income", min: 50, max: 250, step: 1 },
  min_random_user_income: { name: "Minimum Random User Income", min: -5, max: 0, step: 1 },
  max_random_user_income: { name: "Maximum Random User Income", min: 0, max: 5, step: 1 },
  target_profit_margin: { name: "Target Profit Margin", min: 0.1, max: 0.99, step: 0.01 },
  target_profit_margin_adjustment: { name: "Target Profit Margin Adjustment", min: 0, max: 0.99, step: 0.01 },
  min_profit: { name: "Minimum Profit", min: 1, max: 20, step: 1 },
  length_in_minutes_variance: { name: "Length in Minutes Variance", min: 1, max: 20, step: 1 },
  median_length_in_minutes: { name: "Median Length in Minutes", min: 1, max: 20, step: 1 },
  score_variance: { name: "Score Variance", min: 0, max: 10, step: 0.1 },
  mean_score: { name: "Mean Score", min: 0.1, max: 10, step: 0.1 },
  high_cpi_bonus_threshold: { name: "High CPI Bonus Threshold", min: 1, max: 1000, step: 1 },
  high_cpi_bonus_percentage: { name: "High CPI Bonus Percentage", min: 0.1, max: 0.99, step: 0.01 },
  post_high_cpi_bonus_max_reward: { name: "Post High CPI Bonus Max Reward", min: 1, max: 1000, step: 1 },
};

//Default data used for testing UserRewards

export const CONFIG_TEST_FIELDS_INPUT = {
  min_user_income: `td[data-label="Minimum User Income"]`,
  max_user_income: `td[data-label="Maximum User Income"]`,
  min_random_user_income: `td[data-label="Minimum Random User Income"]`,
  max_random_user_income: `td[data-label="Maximum Random User Income"]`,
  target_profit_margin: `td[data-label="Target Profit Margin"]`,
  target_profit_margin_adjustment: `td[data-label="Target Profit Margin Adjustment"]`,
  min_profit: `td[data-label="Minimum Profit"]`,
  length_in_minutes_variance: `td[data-label="Length in Minutes Variance"]`,
  median_length_in_minutes: `td[data-label="Median Length in Minutes"]`,
  score_variance: `td[data-label="Score Variance"]`,
  mean_score: `td[data-label="Mean Score"]`,
  high_cpi_bonus_threshold: `td[data-label="High CPI Bonus Threshold"]`,
  high_cpi_bonus_percentage: `td[data-label="High CPI Bonus Percentage"]`,
  post_high_cpi_bonus_max_reward: `td[data-label="Post High CPI Bonus Max Reward"]`,
};

export const TEST_DATA = [
  {
    id: 1,
    country_code: "Default",
    survey_provider_name: "Default",
    survey_client: "DEFAULT",
    min_user_income: 5,
    max_user_income: 55,
    min_random_user_income: -3,
    max_random_user_income: 3,
    target_profit_margin: 0.5,
    target_profit_margin_adjustment: 0.0,
    min_profit: 2,
    length_in_minutes_variance: 1.5,
    median_length_in_minutes: 12,
    score_variance: 3.0,
    mean_score: 1.4,
    high_cpi_bonus_threshold: 400,
    high_cpi_bonus_percentage: 0.1,
    post_high_cpi_bonus_max_reward: 300,
  },
  {
    id: 2,
    country_code: "FRA",
    survey_provider_name: "IPSOS",
    survey_client: "lightspeed",
    min_user_income: 1,
    max_user_income: 100,
    min_random_user_income: -7,
    max_random_user_income: 3,
    target_profit_margin: 0.5,
    target_profit_margin_adjustment: 0.0,
    min_profit: 2,
    length_in_minutes_variance: 1.5,
    median_length_in_minutes: 12,
    score_variance: 3.0,
    mean_score: 1.4,
    high_cpi_bonus_threshold: 400,
    high_cpi_bonus_percentage: 0.1,
    post_high_cpi_bonus_max_reward: 300,
  },
];
