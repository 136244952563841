import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useCallback, useEffect } from "react";
import { getLastRevenue } from "../../service/ProviderStats";
import useInterval from "../../service/useInterval";
import { usePageVisibility } from "../../service/usePageVisibility";
import { addCommasToNumber } from "../../utils/addCommasToNumber";
import useIsMounted from "../../utils/useIsMounted";
import Tile from "./Tile";

const labels = {
  h: "Last Hour's Gross Revenue",
  d: "Yesterday's Gross Revenue",
  w: "Last Week's Gross Revenue",
  m: "Last Month's Gross Revenue",
};

const PastGrossRevenueTile = ({ panelProvider, interval = "d", timeZone }) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(true)
  const { getAccessTokenSilently } = useAuth0();
  const isVisible = usePageVisibility();
  const [paused, setPaused] = useState(false);
  const isMounted = useIsMounted();

  const getPastRevenue = useCallback(async () => {
    const authToken = await getAccessTokenSilently();
    const { data, error } = await getLastRevenue(
      interval,
      panelProvider,
      authToken,
      timeZone
    );
    const revenue = (+(data?.data?.[0]?.value || 0)/100).toFixed(2);
    return { data: `$${addCommasToNumber(revenue)}`, error: error };
  }, [panelProvider, getAccessTokenSilently, interval, timeZone]);

  useEffect(() => {
    if (panelProvider && interval !== "h") {
      setShouldLoad(true);
      setLoading(true);
      setError("");
      getPastRevenue().then(({ data, error }) => {
        if (isMounted()) {
          setLoading(false);
          setData(data);
          setError(error ? "Revenue data could not be loaded" : "");
        }
      });
    }
  }, [panelProvider, getPastRevenue, interval, timeZone, isMounted]);

  useInterval(
    () => {
      let mounted = true;

      if (panelProvider && isVisible) {
        getPastRevenue().then(({ data, error }) => {
          if (mounted) {
            setData(data);
            setError(error ? "Revenue data could not be loaded" : "" )
            setShouldLoad(false)
          }
        });
      } else if (!isVisible) {
        setPaused(true);
      }

      return () => {
        mounted = false;
      };
    },
    interval === "h" && !paused ? 60000 : null
  );

  useEffect(() => {
    if (isVisible && paused && interval === "h") setPaused(false);
  }, [isVisible, interval, paused, timeZone]);

  return (
    <Tile
      error={error}
      tileTitle={labels[interval]}
      loading={shouldLoad && loading}
      data={data}
    />
  );
};

export default PastGrossRevenueTile;
