import React, { useContext } from "react";
import "./Nav.scss";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import UserContext from "../shared/user-context";
import ProviderDropdown from "./ProviderDropdown";

const Nav = () => {
  const currentUser = useContext(UserContext);
  const { isAuthenticated } = useAuth0();

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navText">
        <p className="header">{currentUser?.email}</p>
        {currentUser?.qmee_admin ? (
          <ProviderDropdown />
        ) : (
          <p>{currentUser?.panel_provider}</p>
        )}
      </div>
      {isAuthenticated ? <LogoutButton /> : <LoginButton />}
    </nav>
  );
};

export default Nav;
