import React, { useState, useEffect, useCallback } from "react";
import ReactEcharts from "echarts-for-react";
import usaJson from "../../assets/geo/USA_geo.json";
import * as echarts from "echarts";
import { MapChart } from "echarts/charts";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";
import { getUserBreakdownByState } from "../../service/ProviderStats";
import { acronymToFullName } from "../../service/stateAcronymToFullName";
import useIsMounted from "../../utils/useIsMounted";

echarts.use([MapChart]);

echarts.registerMap("USA", usaJson, {
  Alaska: {
    left: -131,
    top: 25,
    width: 15,
  },
  Hawaii: {
    left: -110,
    top: 28,
    width: 5,
  },
  "Puerto Rico": {
    left: -76,
    top: 26,
    width: 2,
  },
});

function orderOfMagnitude(n) {
  var order = Math.floor(Math.log10(n) + 0.000000001); 
  return Math.pow(10, order);
}

const getMax = (data = []) => {
  const max = data.reduce((max, currentValue) => {
    if (+currentValue.value > max) return +currentValue.value;
    return max;
  }, 0);

  const roundTo = orderOfMagnitude(max);
  return Math.ceil(max / roundTo) * roundTo;
};


const UserBreakdownMap = ({ panelProvider }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const isMounted = useIsMounted();

  const getData = useCallback(async () => {
    setError("");
    setLoading(true);
    const authToken = await getAccessTokenSilently();
    const { data, error } = await getUserBreakdownByState(panelProvider, authToken);

    if(isMounted()){
      setData(data?.data);
      setLoading(false);
      setError(error ? "US state breakdown could not be loaded" : "");
    }
  }, [panelProvider, getAccessTokenSilently, isMounted]);

  useEffect(() => {
    if (panelProvider) getData();
  }, [panelProvider, getData]);

  const handleRetry = async () => {
    if(!loading) getData();
  };

  const getChartOptions = useCallback(() => {
    return {
      universalTransition: true,
      tooltip: {
        showDelay: 0,
        transitionDuration: 0.2,
        show: true,
        trigger: "item",
      },
      emphasis: {
        areaColor: null,
      },
      visualMap: {
        left: "right",
        min: 0,
        max: getMax(data),
        inRange: {
          color: ["#ffffff", "#847ff9", "#8D4BF3"],
        },
        text: ["High", "Low"],
        calculable: true,
      },
      toolbox: {
        show: false,
      },
      series: [
        {
          name: "Users",
          type: "map",
          roam: false,
          map: "USA",
          select: {
            label: {
              show: false,
            },
            itemStyle: {
              areaColor: null,
              color: null,
              borderColor: "#605AF7",
            },
          },
          emphasis: {
            label: {
              show: false,
            },
            itemStyle: {
              areaColor: "#c17ff9",
            },
          },
          data: data.map(({key, value}) => {
            return {
              name: acronymToFullName(key),
              value: 1*value,
            };
          }),
        },
      ],
    };
  }, [data]);

  const dataLoaded = () => {
    return data && data.length > 0;
  };

  return (
    <div className="graphChart">
      <div className="graphHeaderContainer">
        <p className="chartTitle">
          US State Breakdown
        </p>
        <button
          className="refreshButton"
          title="Reload US state breakdown"
          onClick={handleRetry}
        >
          <RefreshIcon />
        </button>
      </div>

      {error && (
        <p className="errorMessage error">Error loading data: {error}</p>
      )}
      {loading && (
        <div className="graphMessageContainer">
          <div className="loaderSpinner"/>
        </div>
      )}
      {!loading && !error && !dataLoaded() && (
        <div className="graphMessageContainer">
          <p>No Data</p>
        </div>
      )}

      {!error && dataLoaded() && (
        <ReactEcharts option={getChartOptions()} />
      )}
    </div>
  );
};

export default UserBreakdownMap;
