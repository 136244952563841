import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getGenderBreakdown } from "../../service/ProviderStats";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";
import useIsMounted from "../../utils/useIsMounted";

const colors = {
  Female: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: "#8D4BF3",
    },
    {
      offset: 1,
      color: "#605AF7",
    },
  ]),
  Male: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: "#D6D0EC",
    },
    {
      offset: 1,
      color: "#A59CCB",
    },
  ]),
};

const GenderPieChart = ({ panelProvider }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const isMounted = useIsMounted();

  const getData = useCallback(async () => {
    setError("");
    setLoading(true);
    const authToken = await getAccessTokenSilently();
    const { data, error } = await getGenderBreakdown(panelProvider, authToken);

    if (isMounted()) {
      setLoading(false);
      setData(data?.data || []);
      setError(error ? "Gender data could not be loaded" : "");
    }
  }, [panelProvider, getAccessTokenSilently, isMounted]);

  useEffect(() => {
    if (panelProvider) getData();
  }, [panelProvider, getData]);

  const getChartOptions = useCallback(() => ({
    tooltip: {
      trigger: "item",
      formatter: (params) => `${params.name} </br> ${params.value}%`,
    },
    legend: {
      top: "5%",
      left: "center",
    },
    series: [
      {
        data: data.map((value) => {
          return {
            value: (1 * value.value)?.toFixed(2),
            itemStyle: { color: colors[value.key] },
            name: value.key,
          };
        }),
        name: "Gender Breakdown",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
          },
        },
      },
    ],
  }), [data]);

  const dataLoaded = () => {
    return data && data.length > 0;
  };

  return (
    <div className="graphChart">
      <div className="graphHeaderContainer">
        <p className="chartTitle">Gender Breakdown</p>
        <button
          className="refreshButton"
          title="Reload gender breakdown"
          onClick={getData}
        >
          <RefreshIcon />
        </button>
      </div>
      {error && (
        <p className="errorMessage error">Error loading data: {error}</p>
      )}
      {loading && (
        <div className="graphMessageContainer">
          <div className="loaderSpinner"/>
        </div>
      )}
      {!loading && !error && !dataLoaded() && (
        <div className="graphMessageContainer">
          <p>No Data</p>
        </div>
      )}
      {!error && dataLoaded() && (
        <ReactEcharts
          option={getChartOptions()}
          style={{ height: "100%", width: "100%" }}
          className="chart"
        />
      )}
    </div>
  );
};

export default GenderPieChart;
