import React, { useEffect, useContext } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./Loading";
import NotFound from "../pages/NotFound";
import { PanelContext } from "../shared/panel-context";

const ProviderAdminRoute = ({ component, path, user, ...args }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  // Used to make sure the child component rerenders on change of provider
  const panelContext = useContext(PanelContext);

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const options = {
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`
      },
    };
    (async () => {
      await loginWithRedirect(options);
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    <Route
      key={panelContext.panel}
      component={
        isAuthenticated && user
          ? user.provider_admin || user.qmee_admin
            ? component
            : NotFound
          : Loading
      }
      path={path}
      {...args}
    />
  );
};

export default ProviderAdminRoute;
