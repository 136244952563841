import React, { useState, useEffect, useCallback } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { getAgeBreakdown } from "../../service/ProviderStats";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";
import useIsMounted from "../../utils/useIsMounted";


const AgeBreakdownChart = ({ panelProvider }) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const isMounted = useIsMounted();

  const getData = useCallback(async () => {
    const authToken = await getAccessTokenSilently();

    const { data, error } = await getAgeBreakdown(panelProvider, authToken);

    return { data: data?.data || [], error: error };
  }, [panelProvider, getAccessTokenSilently]);

  const handleRetry = () => {
    setError("");
    setLoading(true);
    getData().then(({ data, error }) => {
      if (isMounted()) {
        if (data) setData(data);
        setLoading(false);
        if (error) setError("Age breakdown could not be loaded");
      }
    });
  };

  useEffect(() => {
    if (panelProvider) {
      setError("");
      setLoading(true);
      getData().then(({ data, error }) => {
        if (isMounted()) {
          setLoading(false);
          setData(data);
          if (error) setError("Age breakdown could not be loaded");
        }
      });
    }
  }, [panelProvider, getData, isMounted]);

  const getChartOptions = useCallback(() => {
    const max = data.reduce((acum, curr)=> 1*acum + 1*curr.value, 0)
    return {
      tooltip: {
        trigger: "axis",
        formatter: (params) => `${params?.[0].name} </br> ${params?.[0].value} Users </br> ${(params?.[0].value/max*100)?.toFixed(2)}%`,
        axisPointer: {
          type: "shadow"
        }
      },
      xAxis: {
        type: "category",
        data:  data.map((value) => value.key),
      },
      yAxis: {
        type: "value",
        data:  data.map((value) => value.value),
      },
      grid: { containLabel: true, left: 0, right: 10 },
      series: [
        {
          data: data.map(({key, value}) => {
            return {
              name: key,
              value: 1*(1*value).toFixed(2),
            };
          }),
          type: "bar",
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#8D4BF3",
              },
              {
                offset: 1,
                color: "#5C5BF7",
              },
            ]),
            borderRadius: [10, 10, 0, 0],
          },
          roundCap: true,
        },
      ],
    };
  },[data]);

  const dataLoaded = () => {
    return data && data.length > 0;
  };

  return (
    <div className="graphChart">
      <div className="graphHeaderContainer">
        <p className="chartTitle">Age Breakdown</p>
        <button
          className="refreshButton"
          title="Reload Completes Chart"
          onClick={handleRetry}
        >
          <RefreshIcon />
        </button>
      </div>

      {error && (
        <p className="errorMessage error">Error loading data: {error}</p>
      )}
      {loading && (
        <div className="graphMessageContainer">
          <div className="loaderSpinner"/>
        </div>
      )}
      {!loading && !error && !dataLoaded() && (
        <div className="graphMessageContainer">
          <p>No Data</p>
        </div>
      )}
      {!loading && !error && dataLoaded() && (
        <ReactEcharts
          option={getChartOptions()}
          style={{ height: "100%", width: "100%" }}
          className="chart"
        />
      )}
    </div>
  );
};

export default AgeBreakdownChart;
