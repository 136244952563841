import moment from "moment-timezone";

export const formatMomentToTimeString = (date, timeZone, showMinutes) => {
  if (!timeZone) date = date.utc();
  return date.format(
    `YYYY[-]MM[-]DD[T]HH[:]${showMinutes ? "mm" : "[00]"}[:00]`
  );
};

export const formatDateString = (dateString, timeZone = "UTC") => {
  if (timeZone) {
    return formatMomentToTimeString(moment.tz(dateString, timeZone), timeZone, true);
  } else {
    return formatMomentToTimeString(moment(dateString), timeZone, true);
  }
};
