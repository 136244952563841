import React, { useEffect, useState, useCallback } from "react";
import Tile from "./Tile";
import { useAuth0 } from "@auth0/auth0-react";
import { getConversionRate } from "../../service/ProviderStats";
import useInterval from "../../service/useInterval";
import { usePageVisibility } from "../../service/usePageVisibility";
import useIsMounted from "../../utils/useIsMounted";

const labels = {
  d: "Today's Conversion Rate",
  h: "Conversion Rate (1 hour)",
  m: "Conversion Rate (30 days)",
  w: "Conversion Rate (7 days)",
};

const ConversionRateTile = ({ panelProvider, interval = "d", timeZone }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(true);
  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const [paused, setPaused] = useState(false);
  const isVisible = usePageVisibility();
  const isMounted = useIsMounted();

  const getEarningsData = useCallback(async () => {
    setError("");

    const authToken = await getAccessTokenSilently();
    const { data, error } = await getConversionRate(
      interval,
      panelProvider,
      authToken,
      timeZone
    );

    const conversionRate = `${(1*(data?.data?.[0]?.value || 0)).toFixed(2)}%`;
    return { data: conversionRate, error: error };
  }, [getAccessTokenSilently, panelProvider, interval, timeZone]);

  useEffect(() => {
    if (panelProvider && interval !== "h") {
      setLoading(true);
      setShouldLoad(true);
      getEarningsData().then(({data, error})=>{
          if (isMounted()) {
          setLoading(false);
          setData(data || "");
          setError(error ? "Failed to get conversion rate" : "");
        }
      })
    }
  }, [panelProvider, interval, getEarningsData, timeZone, isMounted]);

  useInterval(
    () => {
      let mounted = true;
      if (panelProvider && isVisible) {
        setLoading(true);
        getEarningsData().then(({ data, error }) => {
          if (mounted) {
            setData(data);
            setError(error ? "Failed to get conversion rate" : "");
            setShouldLoad(false);
          }
        });
      } else if (!isVisible) setPaused(true);

      return () => mounted = false;
    },
    interval === "h" && !paused ? 60000 : null
  );

  useEffect(() => {
    if (isVisible && paused && interval === "h") setPaused(false);
  }, [isVisible, interval, paused, timeZone]);

  return (
    <Tile
      error={error}
      tileTitle={labels[interval]}
      loading={shouldLoad && loading}
      data={data}
      message="Number of Completes / Number of Starts"
    />
  );
};

export default ConversionRateTile;
