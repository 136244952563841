import React, { useEffect, useContext } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./Loading";
import { PanelContext } from "../shared/panel-context"
const defaultReturnTo = () => `${window.location.pathname}${window.location.search}`;

const PrivateRoute = ({ component, path, panelProvider, ...args }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  // Used to make sure the child component rerenders on change of provider
  const panelContext = useContext(PanelContext);

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const options = {
      appState: {
        returnTo: defaultReturnTo,
      },
    };
    (async () => {
      await loginWithRedirect(options);
    })();
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect
  ])
  
  if(panelProvider && panelContext.panel !== panelProvider){
    return null;
  }

  return (
    <Route
      key={panelContext.panel}
      component={isAuthenticated ? component :  Loading }
      path={path}
      {...args}
    />
  );
};

export default PrivateRoute;
