import React, { useState, useEffect } from "react";
import { sprintf } from "sprintf-js";

const CurrencyEquation = ({
  userRevShare,
  conversionRate = 1,
  currencyFormat = null,
  moneyIn = 100,
  handleValidationChange = () => {},
  formatInvalid,
  rewardCalculator = "rev_share",
}) => {
  const [totalFormatted, setTotalFormatted] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);

    try {
      const total =
        moneyIn *
        (!userRevShare ? 0 : userRevShare / 100) *
        ((!conversionRate && conversionRate !== 0) || currencyFormat == null
          ? 1
          : conversionRate);

      const formattedNum =
        1 *
        parseFloat(
          rewardCalculator === "loi_bracket" ? moneyIn : total
        ).toPrecision(7);
      const sprintfFormat = sprintf(currencyFormat, formattedNum);
      setTotalFormatted(sprintfFormat);
      handleValidationChange(false);
    } catch (e) {
      handleValidationChange(true);
      setTotalFormatted("");
      setError(true);
    }
  }, [
    userRevShare,
    conversionRate,
    currencyFormat,
    rewardCalculator,
    moneyIn,
    handleValidationChange,
  ]);

  return (
    <div className="currencyExampleContainer">
      {userRevShare !== undefined && (
        <div>
          <p className={`currencyEquation ${formatInvalid && "invalid"} `}>
            {moneyIn}¢
            {rewardCalculator !== "loi_bracket" && (
              <>
                {" x "}
                <span title="User revenue share">
                  {!userRevShare ? 0 : userRevShare}
                  {"%"}
                </span>
                {" x "}
                <span title="Currency conversion rate">
                  {(!conversionRate && conversionRate !== 0) ||
                  currencyFormat == null
                    ? 1
                    : conversionRate}
                </span>
              </>
            )}
            {" = "}
            <span title="Output with custom currency format">
              {currencyFormat !== null
                ? totalFormatted
                : `${
                    1 *
                    parseFloat(
                      moneyIn *
                        (!userRevShare ? 0 : userRevShare / 100) *
                        ((!conversionRate && conversionRate !== 0) ||
                        currencyFormat == null
                          ? 1
                          : conversionRate)
                    ).toPrecision(7)
                  }¢`}
            </span>
          </p>
          {error && (
            <p className={`currencyEquation ${formatInvalid && "invalid"} `}>
              Invalid currency format
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default CurrencyEquation;
