import axios from "axios";
import { BACKEND_URL } from "./useProviderUserRewardConfigs";

export const postTestEndpoint = async (endpointType, panelProvider, inputData, authToken) => {
  let data = { url: inputData.url };

  switch (endpointType) {
    case "notification":
      if (inputData.user_guid_list) {
        data.guids = inputData.user_guid_list.split(/, |[, ]/);
      }
      break;
    case "postback":
      if (inputData.status) data.status = inputData.status;
      if (inputData.user_guid) data.user_guid = inputData.user_guid;
      break;
    case "user_banned":
      if (inputData.user_guid) data.user_guid = inputData.user_guid;
      endpointType = "user-banned"
      break;
    default:
      return new Error("Invalid Endpoint Type");
  }

  return await axios.post(
    `${BACKEND_URL}/portal/manage/endpoints/${panelProvider}/test/${endpointType}`,
    data,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
};

export const getEndpoints = async (panel_provider, authToken) => {
  let error = "";
  let data = {};

  try {
    const response = await axios.get(
      `${BACKEND_URL}/portal/manage/endpoints/${panel_provider}`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

    data = response.data;
  } catch (err) {
    error = err;
  } finally {
    return { error, data };
  }
};

export const updateEndPoints = async (payload, panel_provider, authToken) => {
  let error = "";

  try {
    await axios.put(
      `${BACKEND_URL}/portal/manage/endpoints/${panel_provider}`,
      {
        redirect: payload.redirect || null,
        postback: payload.postback || null,
        profile_redirect: payload.profile_redirect || null,
        notification: payload.notification || null,
        user_banned: payload.user_banned || null,
        profiler_postback: payload.profiler_postback || null,
        sensitive_data_changed_postback: payload.sensitive_data_changed_postback || null
      },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  } catch (err) {
    error = err;
  } finally {
    return { error };
  }
};
